import styles from './FTE.module.css';

import { Popover, Portal } from '@headlessui/react';
import clsx from 'clsx';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useWindowSize from 'hooks/useWindowSize';
import { SessionAction } from 'store/actions';
import { GameSelector, RoomSelector, SessionSelector, TableSelector } from 'store/selectors';
import posthog from 'posthog-js';
import { hexToRGB } from 'utilities/color';

import { Placement } from '@popperjs/core';
import IconButton from 'components/buttons/IconButton';
import { ReactComponent as Bubble } from 'images/fte/cr/bubble.svg';
import grog from 'images/fte/cr/grog.png';
import jester from 'images/fte/cr/jester.png';
import matt from 'images/fte/cr/matt.png';
import nott from 'images/fte/cr/nott.png';
import penis from 'images/fte/cr/penis.png';
import one from 'images/fte/one.gif';
import three from 'images/fte/three.gif';
import two from 'images/fte/two.gif';
import { ReactComponent as ArrowLeftIcon } from 'images/icons/ArrowLeftIcon.svg';

interface Props {
  refEl: HTMLElement;
  stepId: string;
}

const FTESteps = [
  {
    title: null,
    stepId: 'start',
    media: null,
    copy: 'Get started with your new room by clicking the "Enter" button!',
    faq: '',
    pulseClass: styles.connectPulse,
    popperOptions: {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
    cr: (
      <>
        <img className={clsx(styles.character, styles.matt)} src={matt} alt="matt" />
        <blockquote className={clsx(styles.quote, styles.matt)}>
          <Bubble />
          <p className={styles.quoteCopy}>
            Come in and explore the world of Exandria, where you and your friends sit around and play Dungeons and
            Dragons
          </p>
        </blockquote>
      </>
    ),
  },
  {
    title: 'Audio and Video',
    stepId: 'av',
    media: one,
    copy: 'Put your party front and center with unlimited stable HD video. Catch every smile, gesture, and performance with picture-perfect clarity.',
    faq: '',
    popperOptions: {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
    cr: (
      <>
        <img className={clsx(styles.character, styles.nott)} src={nott} alt="nott 1" />
        <blockquote className={clsx(styles.quote, styles.nott)}>
          <Bubble />
          <p className={styles.quoteCopy}>If I hide back here, maybe Captain Buttonbeard won't find me...</p>
        </blockquote>
      </>
    ),
  },
  {
    title: 'Sheets',
    stepId: 'sheets',
    media: two,
    copy: 'All of the information you need to play at your fingertips. Our intuitive sheets make playing your favorite games easier than ever. Choose from thousands of published sheets, or create your own!',
    faq: '',
    popperOptions: {
      placement: 'right-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [90, 8],
          },
        },
      ],
    },
    mobilePopperOptions: {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    },
    cr: (
      <>
        <img className={clsx(styles.character, styles.grog)} src={grog} alt="grog" />
        <blockquote className={clsx(styles.quote, styles.grog)}>
          <Bubble />
          <p className={styles.quoteCopy}>
            As the leader of this party, I think we are looking good! Now where did I leave that cask of ale...
          </p>
        </blockquote>
      </>
    ),
  },
  {
    title: 'Play Tools',
    stepId: 'maps',
    media: three,
    copy: 'Everything you need to play: chat with your party, roll dice, access your digital books, and upload maps, tokens, or images.',
    faq: '',
    popperOptions: {
      placement: 'left-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [260, 80],
          },
        },
      ],
    },
    mobilePopperOptions: {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    },
    cr: (
      <>
        <img className={clsx(styles.character, styles.jester)} src={jester} alt="jester" />
        <div className={styles.penisWrapper}>
          <img className={styles.penis} src={penis} alt="drawing" />
        </div>
        <blockquote className={clsx(styles.quote, styles.jester)}>
          <Bubble />
          <p className={styles.quoteCopy}>
            "Maybe I could add a quick one here..."
            <br />
            *stops halfway, erases*
            <br />
            "...oh nevermind, the Traveler will know I was here!"
          </p>
        </blockquote>
      </>
    ),
  },
  {
    title: 'Completed',
    stepId: 'completed',
    media: null,
    copy: 'Those are the basics of a room! While you explore your new room in more depth, here are some other helpful next steps:',
    faq: '',
    popperOptions: {},
  },
];

const nextStepIds = ['av', 'sheets', 'maps'];
const prevStepIds = ['sheets', 'maps', 'completed'];

export default function FTE({ refEl, stepId: propStepId }: Props) {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const { guid } = useParams();
  const [viewedFteStep, setViewedFteStep] = useState(false);
  const { showMultiStep, step } = useSelector(SessionSelector.getUserFte);
  const currentUser = useSelector(SessionSelector.currentUser);
  const room = useSelector((state) => RoomSelector.get(state, guid));
  const game = useSelector((state) => GameSelector.getByRoom(state, guid));
  const theme = useSelector((state) => guid && TableSelector.getTheme(state, guid));
  const sheetState = useSelector(TableSelector.isShowingSheet);
  const playState = useSelector(TableSelector.getPlayPanelContent);

  const crRoomGuids = process.env.REACT_APP_CR_ROOMS;
  const showCR = crRoomGuids && guid && crRoomGuids.split(',').includes(guid);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { popperOptions, mobilePopperOptions } = FTESteps[step] || {};
  const {
    update,
    styles: popperStyles,
    attributes,
  } = usePopper(refEl, popperElement, {
    placement:
      ((!size.isMd && mobilePopperOptions ? mobilePopperOptions?.placement : popperOptions?.placement) as Placement) ||
      'auto',
    modifiers: [
      ...((!size.isMd && mobilePopperOptions ? mobilePopperOptions?.modifiers : popperOptions?.modifiers) ?? []),
      {
        name: 'arrow',
        options: {
          element: '[data-popper-arrow]',
        },
      },
    ],
  });

  const onBackClick = useCallback(() => {
    if (!currentUser || !FTESteps[step - 1]) return;
    dispatch(SessionAction.updateFteStep(step - 1));
  }, [currentUser, dispatch, step]);

  const onForwardClick = useCallback(() => {
    if (!currentUser || !FTESteps[step + 1]) return;
    dispatch(SessionAction.updateFteStep(step + 1));
  }, [currentUser, dispatch, step]);

  useEffect(() => {
    return () => {
      if (currentUser && step === 0) {
        dispatch(SessionAction.updateFteStep(step + 1));
      }
    };
  }, [currentUser, dispatch, step]);

  const { stepId, title, media, copy, cr } = FTESteps[step] || {};
  const showFteStep = showMultiStep && !!refEl && stepId === propStepId;

  const [pulsarStyle, setPulsarStyle] = useState<CSSProperties>({});
  const setPulsarPosition = useCallback(() => {
    if (!popperElement) return;
    let refRect = refEl.getBoundingClientRect();
    // Target the button directly if we're in start
    if (stepId === 'start' && refEl.firstElementChild?.tagName === 'button')
      refRect = refEl.firstElementChild.getBoundingClientRect();
    const popperRect = popperElement?.getBoundingClientRect();

    setPulsarStyle({
      left: refRect.left - popperRect.left + 5, // 5px padding
      top: refRect.top - popperRect.top - 40, // 8px padding + 32px gap
    });
  }, [popperElement, refEl, stepId]);

  useEffect(() => {
    // set initial position
    if (popperElement && refEl) {
      requestAnimationFrame(setPulsarPosition);
    }
  }, [popperElement, refEl, setPulsarPosition]);

  useEffect(() => {
    if (update && popperElement) {
      const movePopperEls = () => {
        update();
        requestAnimationFrame(setPulsarPosition);
      };
      let interval = setInterval(movePopperEls, 500); // bcuz animations
      return () => {
        clearInterval(interval);
      };
    }
  }, [update, sheetState, playState, popperElement, refEl, stepId, setPulsarPosition]);

  useEffect(() => {
    if (showMultiStep) {
      document.body.classList.add('is-showing-fte');
    }
    return () => {
      if (step === 4) {
        document.body.classList.remove('is-showing-fte');
      }
    };
  }, [step, showMultiStep]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('is-showing-fte');
    };
  }, []);

  useEffect(() => {
    if (
      !viewedFteStep &&
      showMultiStep &&
      FTESteps[step]?.stepId === propStepId &&
      (!room.gameId || (room.gameId && game))
    ) {
      setViewedFteStep(true);

      posthog.capture(`fte - ${FTESteps[step].stepId}`, {
        'room guid': guid,
        'has game': !!game,
        'game id': game?.id,
        'game title': game?.title,
        'is cr': showCR,
      });
    }
  }, [step, game, guid, propStepId, room, showCR, showMultiStep, viewedFteStep]);

  const style = theme && {
    '--color-theme-base': theme.base && hexToRGB(theme.base),
    '--color-theme-button': theme.button && hexToRGB(theme.button),
    '--color-theme-text': theme.text && hexToRGB(theme.text),
    '--color-theme-accent': theme.accent && hexToRGB(theme.accent),
  };

  if (!showFteStep) return null;

  return (
    <Popover>
      <Portal>
        <Popover.Panel
          static
          className={styles.fteContainer}
          ref={setPopperElement}
          style={{ ...popperStyles.popper, ...style }}
          {...attributes.popper}
        >
          <>
            {stepId === 'start' && <div className={styles.pulsar} style={pulsarStyle} />}
            <div className={styles.titleControls}>
              {prevStepIds.includes(stepId) && (
                <IconButton
                  background="rgb(var(--color-theme-button))"
                  activeBackground="rgb(var(--color-theme-button))"
                  activeHover="rgb(var(--color-theme-accent))"
                  hoverBackground="rgb(var(--color-theme-accent))"
                  color="rgb(var(--color-theme-accent)))"
                  className={styles.backButton}
                  onClick={onBackClick}
                  buttonSize={24}
                  label={'go back'}
                  children={<ArrowLeftIcon />}
                />
              )}
              {title && <h3 className={styles.fteTitle}>{title}</h3>}
              {nextStepIds.includes(stepId) && (
                <IconButton
                  background="rgb(var(--color-theme-button))"
                  activeBackground="rgb(var(--color-theme-button))"
                  activeHover="rgb(var(--color-theme-accent))"
                  hoverBackground="rgb(var(--color-theme-accent))"
                  color="rgb(var(--color-theme-accent)))"
                  className={styles.forwardButton}
                  onClick={onForwardClick}
                  buttonSize={24}
                  label={'go forward'}
                  children={<ArrowLeftIcon />}
                />
              )}
            </div>
            {media && !!size.isMd && <img className={styles.avatar} src={media!} alt={title} />}
            <div className={clsx(styles.fteCopy, stepId === 'maps' && styles.wideCopy)}>{copy}</div>
            <div id="arrow" data-popper-arrow />
            {showCR && cr}
          </>
        </Popover.Panel>
      </Portal>
    </Popover>
  );
}
