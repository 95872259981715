//@flow
import styles from './TableSheetCreator.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { DEFAULT_COLORS } from 'models/Color';
import { RoomAction, RoomSheetAction, SheetAction, TableAction } from 'store/actions';
import {
  GameSelector,
  RoomUserSelector,
  SessionSelector,
  SheetSelector,
  SheetTemplateSelector,
  TableSelector,
} from 'store/selectors';
import type { RoomModel } from 'types/common';

import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import { DropdownInput } from 'components/inputs';
import InfoTooltip from 'components/Sheet2/atoms/InfoTooltip';
import TabsBar from 'components/TabsBar/TabsBar';

type Props = {
  className?: ?string,
  handleSubmit: (a: any) => void,
  room: RoomModel,
};

const isDdbValid = (ddbId) => ddbId && /^[0-9]{3,10}$/.test(ddbId);

const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.trim() === '') errors.name = 'Required';
  return errors;
};

const TABS = ['Room', 'Custom'];
const ddbFaqLink = 'https://playrole.notion.site/D-D-Beyond-Character-Import-af9b3d55aa404575b282ede2394e96ab';

function TableSheetCreator(props: Props) {
  const {
    className,
    handleSubmit,
    room: { gameId, guid, playkitIds, productIds },
  } = props;
  const dispatch = useDispatch();
  const templates = useSelector(SheetTemplateSelector.getPublished);
  const currentUser = useSelector(SessionSelector.currentUser);
  const game = useSelector((state) => GameSelector.get(state, gameId));
  const playkitSheets = useSelector((state) => SheetSelector.getPlaykitSheetsByRoom(state, guid));
  const playkitSheetTemplates = useSelector((state) =>
    SheetTemplateSelector.getPlaykitSheetTemplatesByRoom(state, guid)
  );
  const gameMaterialSheets = useSelector((state) => SheetSelector.getGameSheetsByRoom(state, guid));
  const gameMaterialSheetTemplates = useSelector((state) =>
    SheetTemplateSelector.getGameSheetTemplatesByRoom(state, guid)
  );
  const roomHasPreGenSheetsAndTemplates =
    (!_.isEmpty(playkitSheets) && !_.isEmpty(playkitSheetTemplates)) ||
    (!_.isEmpty(gameMaterialSheets) && !_.isEmpty(gameMaterialSheetTemplates));
  const activeSheet = useSelector(TableSelector.getActiveSheet);

  const sheets = useSelector((state) => {
    if (currentUser) {
      return SheetSelector.getAllByUser(currentUser.id)(state);
    } else {
      return null;
    }
  });

  const userColors = useSelector((state) => {
    if (currentUser) {
      return RoomUserSelector.getColors(state, guid, currentUser.id);
    } else {
      return DEFAULT_COLORS;
    }
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isCustomSelector, setIsCustomSelector] = useState(
    (!productIds || productIds.length < 1) && (!playkitIds || playkitIds.length < 1)
  );
  const [selectedTemplate, setSelectedTemplate] = useState(templates?.[0]?.id);
  const [selectedSheet, setSelectedSheet] = useState(sheets?.[0]?.id);
  const [ddbError, setDdbError] = useState(null);

  const isDndRoom = _.intersection(playkitIds, ['1', '8']).length > 0 || ['39', '40'].includes(gameId);

  useEffect(() => {
    setSelectedTemplate(templates?.[0]?.id);
  }, [templates]);

  useEffect(() => {
    if (!selectedSheet) setSelectedSheet(sheets?.[0]?.id);
  }, [selectedSheet, sheets]);

  useEffect(() => {
    batch(() => {
      dispatch(RoomAction.fetchPlaykitSheets(guid));
      dispatch(RoomAction.fetchPlaykitSheetTemplates(guid));
      dispatch(RoomAction.fetchSheetTemplates(guid));
      dispatch(SheetAction.fetchAll());
    });
  }, [dispatch, guid]);

  const renderDropdown = useMemo(
    () =>
      ({ input, type, options, meta, dropdownValue, dropdownOnChange }) => {
        if (!options) return null;
        const newOptions = {};
        options.forEach((o) => (newOptions[o.id] = { value: o.name }));
        return (
          <DropdownInput
            options={newOptions}
            value={dropdownValue}
            onChange={dropdownOnChange}
            className={styles.dropdown}
            variant="theme"
          >
            {options.map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </DropdownInput>
        );
      },
    []
  );

  const renderTextInput = useMemo(
    () =>
      ({ input, type, placeholder, meta, pattern }) => {
        return (
          <input
            {...input}
            placeholder={placeholder}
            className={clsx(styles.textInput, !!ddbError && styles.textInputError)}
            type={type}
          />
        );
      },
    [ddbError]
  );

  const setActiveSheetAndUser = useCallback(
    (sheetId) => {
      setIsLoading(false);
      batch(() => {
        dispatch(TableAction.addSheet(false));
        dispatch(TableAction.setActiveSheetUser(currentUser.id));
        dispatch(TableAction.setActiveSheet(sheetId));
      });
    },
    [currentUser.id, dispatch]
  );

  const handleNewSheetSuccess = useCallback(
    (sheetId) => {
      dispatch(RoomSheetAction.fetchAll(guid));
      setActiveSheetAndUser(sheetId);
    },
    [dispatch, guid, setActiveSheetAndUser]
  );

  const onAddExistingSheet = (formValues) => {
    setIsLoading(true);
    if (!selectedSheet) return;
    dispatch(RoomSheetAction.create(guid, selectedSheet, (roomSheet) => setActiveSheetAndUser(roomSheet.id)));
  };

  const onAddNewSheet = (formValues) => {
    setIsLoading(true);
    const template = templates.find((t) => t.id === selectedTemplate);
    createSheetFromTemplate(template);
  };

  const onImportDdb = ({ ddbUrlOrId }) => {
    setDdbError(null);
    setIsLoading(true);

    let ddbId;
    if (ddbUrlOrId?.includes('characters')) {
      ddbId = ddbUrlOrId.substring(ddbUrlOrId.indexOf('characters') + 11).split('/')[0];
    } else {
      ddbId = ddbUrlOrId;
    }

    if (!isDdbValid(ddbId)) {
      setDdbError('Invalid Input for Beyond Import');
      setIsLoading(false);
      return;
    }
    const payload = {
      sheet: {
        ddb_id: ddbId,
      },
      room_guid: guid,
    };
    dispatch(
      SheetAction.create(
        payload,
        (newSheet) => {
          handleNewSheetSuccess(newSheet.id);
        },
        (response) => {
          if (response.status === 403) {
            setDdbError(
              'Looks like your character sheet is set to Private. Set it to Public on Beyond to import it on Role.'
            );
          }
          setIsLoading(false);
        }
      )
    );
  };

  const createSheetFromTemplate = useCallback(
    (template, isPlaykitTemplate = false, isGameTemplate = false) => {
      const productIdsIntersection = _.intersection(template.productIds, productIds);
      const payload = {
        sheet: {
          name: template.name,
          sheet_template_id: template.id,
          data: template.data,
        },
        room_guid: guid,
        is_playkit_template: isPlaykitTemplate,
        is_game_template: isGameTemplate,
        game_id: gameId,
        game_title: game?.title,
        product_ids: productIdsIntersection,
      };
      dispatch(SheetAction.create(payload, (newSheet) => handleNewSheetSuccess(newSheet.id)));
    },
    [dispatch, game?.title, gameId, guid, handleNewSheetSuccess, productIds]
  );

  const onCancelClick = () => {
    dispatch(TableAction.addSheet(false));
  };

  const ddbImportForm = () => (
    <>
      <div className={styles.divider}>
        <hr className={styles.hr} />
        <span>or</span>
        <hr className={styles.hr} />
      </div>
      <h3 className={styles.heading}>Import from Beyond</h3>
      <div className={clsx(styles.subtitle, styles.ddbTooltipContainer)}>
        Enter your character sheet URL or ID
        <InfoTooltip
          className={styles.ddbTooltip}
          text={
            <>
              Your character sheet ID is the number
              <br />
              found at the end of your DnD Beyond
              <br />
              character sheet url.
            </>
          }
        />
      </div>
      <form onSubmit={handleSubmit(onImportDdb)} className={styles.selectorForm}>
        <Field component={renderTextInput} name="ddbUrlOrId" type="text" placeholder="Enter Beyond Sheet ID or URL" />
        <Button
          primaryBackground={userColors[0]}
          secondaryBackground={userColors[1]}
          className={styles.ctaButton}
          isLoading={isLoading}
          type="submit"
        >
          Add new sheet
        </Button>
      </form>
      {ddbError && (
        <div className={styles.ddbError}>
          {ddbError}
          <Button to={ddbFaqLink} variant="primary" isExternalLink>
            Read More in our Beyond FAQ Page
          </Button>
        </div>
      )}
    </>
  );

  const renderPreGenSheets = useCallback(() => {
    let preGenSheets = null;
    if (!_.isEmpty(gameMaterialSheets)) preGenSheets = gameMaterialSheets;
    else if (!_.isEmpty(playkitSheets)) preGenSheets = playkitSheets;

    if (!preGenSheets) return null;

    const onLoadPreviewSheet = (preGenSheetId: string) => {
      const sheet = preGenSheets?.find((s) => s.id === preGenSheetId);
      if (!sheet) return;
      let payload = {
        sheet,
        room_guid: guid,
      };

      const playkitId = _.intersection(playkitIds, sheet.playkitIds)[0];
      if (playkitId) {
        payload = { ...payload, playkit: { id: playkitId, sheet_id: sheet.id } };
      }

      const productId = _.intersection(productIds, sheet.productIds)[0];
      if (productId) {
        payload = {
          ...payload,
          product: { id: productId, sheet_id: sheet.id, game_id: gameId, game_title: game?.title },
        };
      }
      dispatch(TableAction.setPreviewSheet(payload));
    };

    return (
      <>
        <h3 className={styles.heading}>Premade Sheets</h3>
        <div className={styles.subtitle}>Ready to jump in and start playing</div>
        <div className={styles.premadeSheets}>
          {preGenSheets.map((s) => (
            <div key={s.id} className={styles.premadeSheet}>
              <Avatar
                avatarUrl={s.avatarUrl ?? null}
                avatarAlt={s.name}
                size={60}
                borderSize={2}
                shape="squircle"
                borderColors={userColors}
                isDdbSheet={!!s.ddbId}
                showSheetDefault
                className={styles.avatar}
              />
              <span className={styles.premadeName}>{s.name}</span>
              <Button
                className={styles.playButton}
                isLoading={isLoading}
                onClick={() => onLoadPreviewSheet(s.id)}
                primaryBackground="rgb(var(--color-theme-accent))"
              >
                View
              </Button>
            </div>
          ))}
        </div>
      </>
    );
  }, [
    dispatch,
    game?.title,
    gameId,
    gameMaterialSheets,
    guid,
    isLoading,
    playkitIds,
    playkitSheets,
    productIds,
    userColors,
  ]);

  const renderPreGenSheetTemplates = useCallback(() => {
    let preGenSheetTemplates = null;
    if (!_.isEmpty(gameMaterialSheetTemplates)) preGenSheetTemplates = gameMaterialSheetTemplates;
    else if (!_.isEmpty(playkitSheetTemplates)) preGenSheetTemplates = playkitSheetTemplates;

    if (!preGenSheetTemplates) return null;

    const onLoadPreviewTemplate = (preGenTemplateId: string) => {
      const preGenTemplate = preGenSheetTemplates?.find((s) => s.id === preGenTemplateId);
      const productIdsIntersection = _.intersection(preGenTemplate?.productIds ?? [], productIds);
      if (!preGenTemplate) return;

      let payload = {
        sheet: preGenTemplate,
        room_guid: guid,
        isTemplate: true,
        is_playkit_template: !_.isEmpty(playkitSheetTemplates),
        is_game_template: !_.isEmpty(gameMaterialSheetTemplates),
        game_id: gameId,
        game_title: game?.title,
        product_ids: productIdsIntersection,
      };

      const playkitId = _.intersection(playkitIds, preGenTemplate.playkitIds)[0];
      if (playkitId) {
        payload = { ...payload, playkit: { id: playkitId, sheet_id: preGenTemplate.id } };
      }

      const productId = _.intersection(productIds, preGenTemplate.productIds)[0];
      if (productId) {
        payload = {
          ...payload,
          product: { id: productId, sheet_id: preGenTemplate.id, game_id: gameId, game_title: game?.title },
        };
      }
      dispatch(TableAction.setPreviewSheet(payload));
    };

    return (
      <>
        <h3 className={styles.heading}>Blank Sheets & Extras</h3>
        <div className={styles.subtitle}>Create a new empty sheet or other game resource</div>
        <div className={styles.premadeSheets}>
          {preGenSheetTemplates.map((s) => (
            <div key={s.id} className={styles.premadeSheet}>
              <Avatar
                avatarUrl={s.avatarUrl ?? null}
                avatarAlt={s.name}
                size={60}
                borderSize={2}
                shape="squircle"
                borderColors={userColors}
                isDdbSheet={!!s.ddbId}
                showSheetDefault
                className={styles.avatar}
              />
              <span className={styles.premadeName}>{s.name}</span>
              <Button
                className={styles.playButton}
                isLoading={isLoading}
                onClick={() => onLoadPreviewTemplate(s.id)}
                primaryBackground="rgb(var(--color-theme-accent))"
              >
                View
              </Button>
            </div>
          ))}
        </div>
      </>
    );
  }, [
    dispatch,
    game?.title,
    gameId,
    gameMaterialSheetTemplates,
    guid,
    isLoading,
    playkitIds,
    playkitSheetTemplates,
    productIds,
    userColors,
  ]);

  return (
    <div className={`${styles.container} ${className || ''} scrollbars-dark`}>
      {(!_.isEmpty(productIds) || !_.isEmpty(playkitIds)) && (
        <div className={styles.sheetSelectorSwitch}>
          <TabsBar
            variant="theme"
            tabs={TABS}
            currentTab={TABS[isCustomSelector ? 1 : 0]}
            tabWidth={162}
            onChange={(tab) => setIsCustomSelector(tab === TABS[1])}
          />
          <InfoTooltip
            className={styles.infoTooltip}
            text={
              <>
                Select from easy pre-made starter sheets, or
                <br />
                load your own custom sheets and
                <br />
                characters into the room.
              </>
            }
          />
        </div>
      )}

      <div
        className={clsx(
          styles.content,
          'scrollbars-dark',
          (!_.isEmpty(productIds) || !_.isEmpty(playkitIds)) && styles.isActive
        )}
      >
        {isCustomSelector ? (
          <>
            {!_.isEmpty(sheets) && (
              <>
                <h3 className={styles.heading}>Load Custom Sheets</h3>
                <div className={styles.subtitle}>Choose from your existing sheets</div>
                <form onSubmit={handleSubmit(onAddExistingSheet)} className={styles.selectorForm}>
                  <Field
                    component={renderDropdown}
                    name="loadCustomSheet"
                    options={sheets}
                    dropdownValue={selectedSheet}
                    dropdownOnChange={setSelectedSheet}
                  />
                  <Button
                    primaryBackground={userColors[0]}
                    secondaryBackground={userColors[1]}
                    className={styles.ctaButton}
                    isLoading={isLoading}
                    type="submit"
                  >
                    Load Sheet
                  </Button>
                </form>
                <div className={styles.divider}>
                  <hr className={styles.hr} />
                  <span>or</span>
                  <hr className={styles.hr} />
                </div>
              </>
            )}
            <h3 className={styles.heading}>New Custom Sheets</h3>
            <div className={styles.subtitle}>Start building a new custom sheet</div>
            <form onSubmit={handleSubmit(onAddNewSheet)} className={styles.selectorForm}>
              <Field
                component={renderDropdown}
                name="newCustomSheet"
                options={templates}
                dropdownValue={selectedTemplate}
                dropdownOnChange={setSelectedTemplate}
              />
              <Button
                primaryBackground={userColors[0]}
                secondaryBackground={userColors[1]}
                className={styles.ctaButton}
                isLoading={isLoading}
                type="submit"
              >
                Add new sheet
              </Button>
            </form>
            {!isDndRoom && ddbImportForm()}
          </>
        ) : (
          <>
            {renderPreGenSheets()}
            {roomHasPreGenSheetsAndTemplates && (
              <div className={styles.divider}>
                <hr className={styles.hr} />
                <span>or</span>
                <hr className={styles.hr} />
              </div>
            )}
            {renderPreGenSheetTemplates()}
            {isDndRoom && ddbImportForm()}
          </>
        )}
        {!!activeSheet && (
          <div className={styles.cancelButton}>
            <Button
              variant="cancel"
              color="var(--color-white)"
              hoverColor="var(--color-dark-text)"
              onClick={onCancelClick}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default reduxForm({ form: 'createSheet', validate, enableReinitialize: true })(TableSheetCreator);
