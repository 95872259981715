import { Popover } from '@headlessui/react';
import styles from './Toast.module.css';
import clsx from 'clsx';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';
import ReactDOM from 'react-dom';

type Props = {
  onClose: () => void;
  title?: string;
  body: React.ReactNode;
};

export default function Toast({ body, onClose, title }: Props) {
  const modalRoot = document.getElementById('modal');
  if (!modalRoot) return null;
  return ReactDOM.createPortal(
    <Popover className={clsx(styles.container)}>
      <div className={clsx(styles.panelWrapper)}>
        <button className={clsx('button-reset', styles.closeButton)} onClick={onClose}>
          <XIcon className="rui-w-[10px] rui-h-[10px] rui-fill-role-light-100" />
        </button>

        <Popover.Panel className={styles.panel} static>
          {title && <h2 className={styles.title}>{title}</h2>}
          <div>{body}</div>
        </Popover.Panel>
      </div>
    </Popover>,
    modalRoot
  );
}
