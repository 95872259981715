import styles from './AssetTray.module.css';

import { intersection, last } from 'lodash';
import { useCallback, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import useWindowSize from 'hooks/useWindowSize';
import Document, { DocumentType } from 'models/Document';
import {
  AssetAction,
  AudioPlayerAction,
  MediaAction,
  RoomAction,
  RoomDocumentAction,
  TableAction,
} from 'store/actions';
import {
  DocumentSelector,
  GameSelector,
  ProductSelector,
  RoomSelector,
  SessionSelector,
  TableSelector,
} from 'store/selectors';
import { RoomTab } from 'types/room';
import posthog from 'posthog-js';
import useVideoContext from 'video/hooks/useVideoContext';

import AssetCard from 'components/cards/AssetCard';

export default function RoomDocument({ roomDocument, roomGuid, className, style, theme }) {
  const { room: videoRoom } = useVideoContext();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const currentUser = useSelector(SessionSelector.currentUser);
  const document = useSelector((state) => DocumentSelector.get(state, roomDocument.id));
  const asset = useMemo(() => new Document(document), [document]);
  const room = useSelector((state) => RoomSelector.get(state, roomGuid));
  const roomGame = useSelector((state) => GameSelector.getByRoom(state, roomGuid));
  const games = useSelector(GameSelector.getAll);
  const products = useSelector(ProductSelector.getAll);

  const activeRoomDocument = useSelector(TableSelector.getActiveRoomDocument);
  const sharedRoomDocument = useSelector(TableSelector.getSharedRoomDocument);
  const privateRoomDocument = useSelector(TableSelector.getPrivateRoomDocument);

  const isDefaultLobbyAudio = room.settings.defaultLobbyAudioGuidId === roomDocument.guidId;

  const onAssetShowTable = useCallback(() => {
    const { guidId } = roomDocument;

    batch(() => {
      dispatch(TableAction.setSharedRoomDocument(guidId));

      if (privateRoomDocument?.guidId === guidId || !videoRoom) {
        dispatch(TableAction.setPrivateRoomDocument(null));
      }

      if (!activeRoomDocument || activeRoomDocument.guidId !== guidId) {
        dispatch(TableAction.setActiveRoomDocument(guidId));
        dispatch(MediaAction.setCanvasScale(1));
      }

      const room_document = { is_shared: true };
      dispatch(RoomDocumentAction.update(roomGuid, asset.id, { room_document, user_id: roomDocument.ownerId }));

      dispatch(AssetAction.showMenu(null));
      if (size.isMd) dispatch(TableAction.setPlayPanelContent(null));
      else dispatch(TableAction.setCurrentTab(RoomTab.Party));
    });

    const sharedProductIds = intersection(asset?.productIds, currentUser.unlockedProductIds);

    posthog.capture('asset - show party', {
      'asset type': asset.type,
      'from playkit': asset.playkitIds.length > 0,
      'from game': asset.gameIds.length > 0,
      'from product': asset.productIds.length > 0,
      'playkit ids': asset.playkitIds,
      'game id': roomGame?.id,
      'game title': roomGame?.title,
      'product ids': sharedProductIds,
    });
  }, [
    activeRoomDocument,
    asset.gameIds.length,
    asset.id,
    asset.playkitIds,
    asset.productIds,
    asset.type,
    currentUser.unlockedProductIds,
    dispatch,
    privateRoomDocument?.guidId,
    roomDocument,
    roomGame?.id,
    roomGame?.title,
    roomGuid,
    size.isMd,
    videoRoom,
  ]);

  const onAssetViewPrivately = useCallback(() => {
    const { guidId } = roomDocument;

    batch(() => {
      dispatch(TableAction.setPrivateRoomDocument(guidId));

      if (sharedRoomDocument?.guidId === guidId || !videoRoom) {
        dispatch(TableAction.setSharedRoomDocument(null));
        const room_document = { is_shared: false };
        dispatch(RoomDocumentAction.update(roomGuid, asset.id, { room_document, user_id: roomDocument.ownerId }));
      }

      if (!activeRoomDocument || activeRoomDocument.guidId !== guidId) {
        dispatch(TableAction.setActiveRoomDocument(guidId));
        dispatch(MediaAction.setCanvasScale(1));
      }

      dispatch(AssetAction.showMenu(null));
      if (size.isMd) dispatch(TableAction.setPlayPanelContent(null));
      else dispatch(TableAction.setCurrentTab(RoomTab.Party));
    });

    const sharedProductIds = intersection(asset.productIds, currentUser.unlockedProductIds);

    posthog.capture('asset - view privately', {
      'asset type': asset.type,
      'from playkit': asset.playkitIds.length > 0,
      'from game': asset.gameIds.length > 0,
      'from product': asset.productIds.length > 0,
      'playkit ids': asset.playkitIds,
      'game id': roomGame?.id,
      'game title': roomGame?.title,
      'product ids': sharedProductIds,
    });
  }, [
    activeRoomDocument,
    asset.gameIds.length,
    asset.id,
    asset.playkitIds,
    asset.productIds,
    asset.type,
    currentUser.unlockedProductIds,
    dispatch,
    roomDocument,
    roomGame?.id,
    roomGame?.title,
    roomGuid,
    sharedRoomDocument?.guidId,
    size.isMd,
    videoRoom,
  ]);

  const permissions = useMemo(() => {
    const isHost = room?.userId === currentUser.id;
    const isUploader = asset?.userId === currentUser.id;
    const isPurchaser = intersection(currentUser.unlockedProductIds, asset?.productIds).length > 0;
    const isAdder = roomDocument.ownerType === 'User' && roomDocument.ownerId === currentUser.id;
    const isViewer = roomDocument.isPublic;
    return {
      isAdder,
      isHost,
      isPurchaser,
      isUploader,
      isViewer,
    };
  }, [asset, currentUser, roomDocument, room]);

  const isAudio = asset.type.toLowerCase() === DocumentType.AUDIO;

  const menuItems = useMemo(() => {
    const { isHost, isUploader, isAdder } = permissions;

    const { id, fileUrl, externalUrl, downloadUrl } = asset;

    const sharedProductIds = intersection(asset.productIds, currentUser.unlockedProductIds);

    const items = [];

    if (!asset.isPdf() && !asset.isToken() && !asset.isLink() && !isAudio) {
      items.push({ label: 'View Privately', onClick: onAssetViewPrivately });

      if (!asset.isApp() && (isAdder || isHost)) {
        items.push({ label: 'Show Party', onClick: onAssetShowTable });
      }
    }

    if (!isAudio) {
      items.push({
        label: 'View in New Window',
        url: externalUrl ?? fileUrl,
        urlCallback: () => {
          posthog.capture('asset - view in new window', {
            'asset type': asset.type,
            'from playkit': asset.playkitIds.length > 0,
            'from game': asset.gameIds.length > 0,
            'from product': asset.productIds.length > 0,
            'playkit ids': asset.playkitIds,
            'game id': roomGame?.id,
            'game title': roomGame?.title,
            'product ids': sharedProductIds,
          });
        },
      });
    }

    if (isAdder) {
      items.push({
        label: 'Make Private',
        isActive: !!roomDocument && !roomDocument.isPublic,
        onToggle: () => {
          const room_document = { is_public: !roomDocument.isPublic };
          dispatch(RoomDocumentAction.update(roomGuid, id, { room_document, user_id: roomDocument.ownerId }));
        },
      });
    }

    if (isHost && isAudio) {
      items.push({
        label: isDefaultLobbyAudio ? 'Remove Lobby Music' : 'Use as Lobby Music',
        onClick: () => {
          dispatch(
            RoomAction.update(roomGuid, {
              settings: { ...room.settings, defaultLobbyAudioGuidId: isDefaultLobbyAudio ? null : roomDocument.guidId },
            })
          );
        },
      });
    }

    if (isUploader) {
      if (asset.isMap()) {
        items.push({
          label: 'Edit Map Size',
          onClick: () => {
            batch(() => {
              dispatch(AssetAction.showMenu(null));
              dispatch(AssetAction.editMap(id));
            });
          },
        });
      }

      if (asset.isToken()) {
        items.push({
          label: 'Edit Token Scale',
          onClick: () => {
            batch(() => {
              dispatch(AssetAction.showMenu(null));
              dispatch(AssetAction.editToken(id));
            });
          },
        });
      }

      items.push({
        label: 'Rename',
        onClick: () => {
          batch(() => {
            dispatch(AssetAction.showMenu(null));
            dispatch(AssetAction.rename(id));
          });
        },
      });
    }

    if (isAdder && downloadUrl && (asset.isImage() || asset.isToken() || asset.isMap() || asset.isPdf())) {
      items.push({
        label: 'Download',
        url: downloadUrl,
        download: true,
        urlCallback: () => {
          posthog.capture('asset - download', {
            'asset type': asset.type,
            'from playkit': asset.playkitIds.length > 0,
            'from game': asset.gameIds.length > 0,
            'from product': asset.productIds.length > 0,
            'playkit ids': asset.playkitIds,
            'game id': roomGame?.id,
            'game title': roomGame?.title,
            'product ids': sharedProductIds,
          });
        },
      });
    }

    const isAutoCopiedDocument = isHost && isAdder && asset.gameIds.includes(roomGame?.id);
    if (isAdder && !isAutoCopiedDocument) {
      items.push({
        label: 'Remove From Room',
        onClick: () => {
          batch(() => {
            dispatch(AssetAction.showMenu(null));
            dispatch(RoomDocumentAction.delete(roomGuid, id, roomDocument.ownerId));
          });
        },
      });
    }

    return items;
  }, [
    asset,
    currentUser.unlockedProductIds,
    dispatch,
    isAudio,
    isDefaultLobbyAudio,
    onAssetShowTable,
    onAssetViewPrivately,
    permissions,
    room.settings,
    roomDocument,
    roomGame?.id,
    roomGame?.title,
    roomGuid,
  ]);

  const tags = useMemo(() => {
    const tags = [];
    if (isDefaultLobbyAudio) tags.push({ title: 'Lobby Music', color: 'rgb(var(--color-theme-accent))' });
    if (asset.playkitIds.length > 0) tags.push({ title: 'Quick-Start', color: 'var(--color-teal)' });
    if (asset.gameIds.length > 0 && games?.length > 0) {
      const game = games.find((game) => game.id === asset.gameIds[0]);
      if (game)
        tags.push({
          title: game.title,
          color: 'var(--color-secondary-light)',
        });
    }
    if (asset.productIds.length > 0 && products?.length > 0) {
      const product = products.find((product) => product.id === asset.productIds[0]);
      if (product)
        tags.push({
          title: product.title,
          color: 'var(--color-secondary-light)',
        });
    }
    if (roomDocument.isPublic) tags.push({ title: 'Shared', color: 'var(--color-purple)' });
    return tags;
  }, [
    asset.gameIds,
    asset.playkitIds.length,
    asset.productIds,
    games,
    isDefaultLobbyAudio,
    products,
    roomDocument.isPublic,
  ]);

  const onClickPlayAudio = useCallback(() => {
    const sharedProductIds = intersection(asset?.productIds, currentUser.unlockedProductIds);

    dispatch(AudioPlayerAction.setRoomDocument(roomDocument.guidId));

    posthog.capture('asset - play audio', {
      'from game': asset.gameIds.length > 0,
      'from product': asset.productIds.length > 0,
      'game id': roomGame?.id,
      'game title': roomGame?.title,
      'product ids': sharedProductIds,
      extension: last(asset.fileUrl?.split('.')),
      duration: asset.metadata.duration,
    });
  }, [asset, currentUser.unlockedProductIds, dispatch, roomDocument, roomGame?.id, roomGame?.title]);

  return (
    <div className={className} style={style}>
      <AssetCard
        asset={asset}
        roomDocument={roomDocument}
        menuItems={menuItems}
        onMainClick={isAudio ? onClickPlayAudio : undefined}
        tags={tags}
        showActions
        isClickable
        className={styles.playPanelCard}
        theme={theme}
        variant="theme"
      />
    </div>
  );
}
