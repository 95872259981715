import { createAction, handleError } from './utilities';
import SessionSelector from 'store/selectors/SessionSelector';
import posthog from 'posthog-js';

import role from 'apis/role';

export default class RoomSheetAction {
  static CREATE = 'RoomSheetAction.CREATE';
  static DELETE = 'RoomSheetAction.DELETE';
  static FETCH = 'RoomSheetAction.FETCH';
  static FETCH_ALL = 'RoomSheetAction.FETCH_ALL';
  static REMOVE = 'RoomSheetAction.REMOVE';
  static UPDATE = 'RoomSheetAction.UPDATE';

  static create = (guid, id, callback) => async (dispatch, getState) => {
    const data = { sheet_id: id, socket_id: SessionSelector.getSocketId(getState()) };
    return role.post(`/rooms/${guid}/room_sheets`, data).then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(RoomSheetAction.CREATE, obj));
        posthog.capture('table - add sheet');
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomSheetAction.CREATE, response)
    );
  };

  static delete = (guid, id, callback) => async (dispatch, getState) => {
    const data = { socket_id: SessionSelector.getSocketId(getState()) };
    return role.delete(`/rooms/${guid}/room_sheets/${id}`, { data }).then(
      (response) => {
        const obj = { guid, id };
        dispatch(createAction(RoomSheetAction.DELETE, obj));
        posthog.capture('table - remove sheet');
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomSheetAction.DELETE, response)
    );
  };

  static fetch = (guid, id, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/room_sheets/${id}`).then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(RoomSheetAction.FETCH, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomSheetAction.FETCH, response)
    );
  };

  static fetchAll = (guid, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/room_sheets`).then(
      (response) => {
        const objs = response.data.data.map((obj) => {
          const { id, attributes } = obj;
          return { id, ...attributes };
        });
        dispatch(createAction(RoomSheetAction.FETCH_ALL, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, RoomSheetAction.FETCH_ALL, response)
    );
  };

  static remove = (guid, id) => {
    return createAction(RoomSheetAction.REMOVE, { guid, id });
  };

  static update = (guid, id, formValues, callback) => async (dispatch, getState) => {
    return role
      .patch(`/rooms/${guid}/room_sheets/${id}`, { ...formValues, socket_id: SessionSelector.getSocketId(getState()) })
      .then(
        (response) => {
          const { id, attributes } = response.data.data;
          const obj = { id, ...attributes };
          dispatch(createAction(RoomSheetAction.UPDATE, obj));
          if (callback) callback(obj);
        },
        ({ response }) => handleError(dispatch, RoomSheetAction.UPDATE, response)
      );
  };
}
