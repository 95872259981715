import Bugsnag from '@bugsnag/js';

import { SessionAction, UserAction } from 'store/actions';

import role, { getAuthCookie, setAuthCookie } from 'apis/role';

export const fetchInitialState = () => ({
  token: getAuthCookie(),
});

const INITIAL_STATE = {
  redirectToPath: null,
  requestedPasswordReset: false,
  socketId: null,
  user: null,
  invite: null,
  ...fetchInitialState(),
};

const bugsnagKey = process.env.REACT_APP_BUGSNAG_API_KEY;

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case SessionAction.CLEAR_INVITE:
      return { ...state, invite: null };

    case SessionAction.CLEAR_REQUESTED_PASSWORD_RESET:
      return { ...state, requestedPasswordReset: false };

    case SessionAction.FETCH_CURRENT_USER:
    case SessionAction.SIGN_UP_GUEST:
    case UserAction.UPDATE:
      if (bugsnagKey) Bugsnag.setUser(payload.id);
      return { ...state, user: payload };

    case SessionAction.FETCH_INVITE:
      return { ...state, invite: payload };

    case SessionAction.REDIRECT_TO:
      return { ...state, redirectToPath: payload };

    case SessionAction.REQUEST_PASSWORD_RESET:
      return { ...state, requestedPasswordReset: true };

    case SessionAction.SET_SOCKET_ID:
      return { ...state, socketId: payload };

    case SessionAction.UPDATE_FTE_STEP:
      if (state.user)
        return {
          ...state,
          user: {
            ...state.user,
            metadata: { ...state.user.metadata, fte: { ...state.user.metadata.fte, step: payload } },
          },
        };
      else return state;

    case SessionAction.UPDATE_SHOW_MULTI_STEP:
      if (state.user)
        return {
          ...state,
          user: {
            ...state.user,
            metadata: { ...state.user.metadata, fte: { ...state.user.metadata.fte, showMultiStep: payload } },
          },
        };
      else return state;

    case SessionAction.UPDATE_FTE_GENRE:
      if (state.user)
        return {
          ...state,
          user: {
            ...state.user,
            metadata: { ...state.user.metadata, fte: { ...state.user.metadata.fte, genre: payload } },
          },
        };
      else return state;

    case SessionAction.UPDATE_SHOW_ROOM_CREATE:
      if (state.user)
        return {
          ...state,
          user: {
            ...state.user,
            metadata: { ...state.user.metadata, fte: { ...state.user.metadata.fte, showRoomCreate: payload } },
          },
        };
      else return state;

    case SessionAction.UPDATE_FTE_GAME_QUERY:
      if (state.user)
        return {
          ...state,
          user: {
            ...state.user,
            metadata: { ...state.user.metadata, fte: { ...state.user.metadata.fte, gameQuery: payload } },
          },
        };
      else return state;

    case SessionAction.SIGN_IN:
    case SessionAction.SIGN_UP:
      const { token } = payload;
      setAuthCookie(token);
      role.defaults.headers['Authorization'] = `Bearer ${token}`;
      return { ...state, token };

    case SessionAction.SIGN_OUT:
      setAuthCookie(null);
      delete role.defaults.headers['Authorization'];
      if (bugsnagKey) Bugsnag.setUser(null);
      return { ...state, token: null, user: null };

    default:
      return state;
  }
}
