// @flow
import type { Node } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import type { SheetModel, SheetProps } from './types';
import { ELEMENT_TYPE, INPUT_TYPE } from 'constants/sheet';
import { DieType } from 'models/Die';
import Modifier, { ModifierType } from 'models/Modifier';
import { RollAction } from 'store/actions';
import posthog from 'posthog-js';

import Header from './Header';
import Main from './Main';

type Props = {
  ...SheetProps,
  canCollapse: boolean,
  isPreview: boolean,
  model: SheetModel,
  sheets?: SheetModel[],
  setupModifiers: boolean,
};

function Sheet(props: Props): Node {
  const {
    colors,
    canCollapse,
    isCollapsed,
    isMenuActive,
    isPreview,
    menuItems,
    model,
    onChange,
    onChangeAvatar,
    onChangeCollapse,
    onMenuClick,
    readOnly,
    setupModifiers,
    userName,
    theme,
  } = props;
  const {
    id,
    avatarUrl,
    data: { sections = [] },
    isDefaultAvatar,
    name,
  } = model;

  const isDdbSheet = !!model.ddbId;
  const dispatch = useDispatch();
  const onChangeName = useCallback((name) => onChange({ ...model, name }), [model, onChange]);

  const addModifiers = useCallback(
    ({ id: elementId, elementType, items }) => {
      switch (elementType) {
        case ELEMENT_TYPE.buff:
        case ELEMENT_TYPE.fieldBuff:
          const buff = items.find((o) => o.inputType === INPUT_TYPE.buff);
          if (buff) {
            const modifier = new Modifier({
              id: uuid(),
              sheetId: model.id,
              inputId: elementId,
              value: parseInt(buff.value ?? buff.defaultValue),
              type: ModifierType.BUFF,
            });
            dispatch(RollAction.addModifier(modifier));
          }
          break;

        case ELEMENT_TYPE.reference:
          const reference = items.find((o) => o.inputType === INPUT_TYPE.reference);
          if (reference) {
            const modifier = new Modifier({
              id: uuid(),
              sheetId: model.id,
              inputId: elementId,
              value: parseInt(reference.value ?? reference.defaultValue),
              type: ModifierType.TARGET,
            });
            dispatch(RollAction.addModifier(modifier));
          }
          break;

        case ELEMENT_TYPE.dicePool:
          const diceCount = items.find((o) => o.inputType === INPUT_TYPE.diceCount);
          const diceSides = items.find((o) => o.inputType === INPUT_TYPE.diceSides);
          const diceType = items.find((o) => o.inputType === INPUT_TYPE.diceType);
          const diceColor = items.find((o) => o.inputType === INPUT_TYPE.diceColor);
          if (diceCount && diceSides && diceType) {
            const modifier = new Modifier({
              id: uuid(),
              sheetId: model.id,
              inputId: elementId,
              value: parseInt(diceSides.value ?? diceSides.defaultValue),
              count: parseInt(diceCount.value ?? diceCount.defaultValue),
              type: ModifierType.DICE,
              diceType: diceType.value ?? diceType.defaultValue ?? DieType.NUMBER,
              color: diceColor?.value ?? diceColor?.defaultValue ?? '',
            });
            dispatch(RollAction.addModifier(modifier));
          }
          break;

        default:
          break;
      }
    },
    [dispatch, model.id]
  );

  useEffect(() => posthog.capture('sheet - view sheet'), [model.id]);

  useEffect(() => {
    if (!setupModifiers || isDdbSheet) return;
    sections.forEach(({ elements }) => {
      elements.forEach((element) => {
        if (element.elementType === ELEMENT_TYPE.group) {
          if (!!element.metadata.collection) {
            element.metadata.collection.forEach((group) => {
              group.items.forEach((o) => addModifiers(o));
            });
          } else {
            element.items.forEach((o) => addModifiers(o));
          }
        } else {
          addModifiers(element);
        }
      });
    });
  }, [addModifiers, isDdbSheet, sections, setupModifiers]);

  return (
    <Main
      {...props}
      header={
        <Header
          id={id}
          colors={colors}
          avatarUrl={avatarUrl}
          name={name}
          userName={userName || ''}
          menuItems={menuItems}
          canCollapse={canCollapse}
          canEdit={!readOnly}
          isDdbSheet={isDdbSheet}
          isDefaultAvatar={isDefaultAvatar}
          isCollapsed={isCollapsed}
          isMenuActive={isMenuActive}
          isPreview={isPreview}
          onChangeAvatar={onChangeAvatar}
          onChangeCollapse={onChangeCollapse}
          onChangeName={onChangeName}
          onMenuClick={onMenuClick}
          theme={theme}
        />
      }
    />
  );
}

Sheet.defaultProps = {
  canCollapse: false,
  idKey: 'id',
  isCollapsed: false,
  isMenuActive: false,
  isPreview: false,
  setupModifiers: false,
};

export default Sheet;
