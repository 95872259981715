//@flow
import styles from './PreviewPage.module.css';

import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useWindowSize from 'hooks/useWindowSize';
import { GameAction, ProductAction, TableAction } from 'store/actions/';
import { GameSelector, ProductSelector, RoomDocumentSelector, TableSelector } from 'store/selectors';
import { RoomTab } from 'types/room';
import posthog from 'posthog-js';
import { stylizeTheme } from 'utilities/color';

import MainLayout from 'components/layouts/MainLayout';
import Metatags from 'components/Metatags';
import PageLoading from 'components/PageLoading';
import PlayPanel from 'components/PlayPanel';
import RoomControls from 'components/RoomControls';
import PreviewRoomSheet from 'components/TableSheet/PreviewRoomSheet';
import { ReactComponent as D20Icon } from 'images/dice/D20.svg';
import { ReactComponent as BooksIcon } from 'images/icons/BooksIcon.svg';
import { ReactComponent as GridViewIcon } from 'images/icons/GridViewIcon.svg';
import { ReactComponent as PriceTagIcon } from 'images/icons/PriceTagIcon.svg';
import { ReactComponent as SheetIcon } from 'images/icons/SheetIcon.svg';
import PreviewTableView from 'room/PreviewTableView';
import { VideoProvider } from 'video/VideoProvider';

function PreviewPage({ forceFetch = false }) {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const game = useSelector((state) => GameSelector.getBySlug(state, slug));
  const freeProduct = useSelector((state) => {
    if (game) {
      return ProductSelector.get(state, game.defaultFreeProductGuid);
    } else {
      return null;
    }
  });
  const products = useSelector((state) => (game ? ProductSelector.getLockedByGame(state, game.id) : []));
  const theme = useSelector((state) => slug && RoomDocumentSelector.getPreviewThemeBySlug(state, slug));
  const isShowingSheet = useSelector(TableSelector.isShowingSheet);
  const isShowingPanel = useSelector(TableSelector.isPlayPanelOpen);
  const currentTab = useSelector(TableSelector.getCurrentTab);

  const [isTOCOpen, setIsTOCOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showStoreTab = !!game && products.length > 0;
  const title = useMemo(() => (game?.title ? `${game.title} Game Room` : 'Game Room'), [game?.title]);
  const onChangeTOCOpen = useCallback((isOpen) => setIsTOCOpen(isOpen), []);

  useEffect(() => {
    return () => dispatch(TableAction.reset());
  }, [dispatch]);

  useEffect(() => {
    batch(() => {
      if (forceFetch) dispatch(ProductAction.fetchAll({ game_slug: slug, force: true }));
      else dispatch(ProductAction.fetchAll({ game_slug: slug }));
      dispatch(GameAction.fetch(slug));
      dispatch(TableAction.setPreviewRoom(slug));
    });
  }, [dispatch, forceFetch, slug]);

  useEffect(() => {
    if (!game) return;
    const logHeartbeat = () => {
      posthog.capture('preview page - session length heartbeat', {
        'game id': game.id,
        'game title': game.title,
      });
    };
    logHeartbeat();
    const heartbeatTimer = setInterval(logHeartbeat, 300000);
    return () => clearInterval(heartbeatTimer);
  }, [game]);

  useEffect(() => {
    if (!freeProduct) return;
    setIsLoading(true);
    batch(() => {
      Promise.all([
        dispatch(ProductAction.fetchSheets(freeProduct.guid)),
        dispatch(ProductAction.fetchTemplates(freeProduct.guid)),
        dispatch(ProductAction.fetchDocuments(freeProduct.guid, slug)),
      ]).then(() => setIsLoading(false));
    });
  }, [dispatch, freeProduct, slug]);

  useEffect(() => {
    document.body.classList.add('is-room-page');
    return () => document.body.classList.remove('is-room-page');
  }, []);

  return (
    <VideoProvider>
      <MainLayout navChildren={<div />} variant="rainbow-only">
        <Metatags title={title} />
        {game ? (
          <>
            <div className={styles.container} style={theme && stylizeTheme(theme)}>
              <div
                className={clsx(
                  styles.room,
                  !isShowingSheet && styles.hideSheet,
                  isTOCOpen && styles.tocOpen,
                  isShowingPanel && styles.showPanel
                )}
              >
                <div
                  className={clsx(styles.roomColumn, styles.sheet, currentTab === RoomTab.Sheets && styles.isActive)}
                >
                  <PreviewRoomSheet isLoading={isLoading} isTOCOpen={isTOCOpen} onChangeTOCOpen={onChangeTOCOpen} />
                </div>
                <div
                  className={clsx(styles.roomColumn, styles.upsell, currentTab === RoomTab.Party && styles.isActive)}
                >
                  <PreviewTableView />
                </div>
                <div
                  className={clsx(
                    styles.roomColumn,
                    styles.panel,
                    ![RoomTab.Party, RoomTab.Sheets].includes(currentTab) && styles.isActive
                  )}
                >
                  <PlayPanel theme={theme} />
                </div>
              </div>
            </div>

            {!size.isMd && (
              <>
                <RoomControls theme={theme} className={styles.roomControls} />
                <nav className={styles.navTabs} style={theme && stylizeTheme(theme)}>
                  <button
                    onClick={() => dispatch(TableAction.setCurrentTab(RoomTab.Party))}
                    className={clsx('button-reset', styles.navButton, currentTab === RoomTab.Party && styles.isActive)}
                  >
                    <GridViewIcon />
                    <span>Party</span>
                  </button>
                  <button
                    onClick={() => dispatch(TableAction.setCurrentTab(RoomTab.Sheets))}
                    className={clsx('button-reset', styles.navButton, currentTab === RoomTab.Sheets && styles.isActive)}
                  >
                    <SheetIcon />
                    <span>Sheets</span>
                  </button>
                  <button
                    onClick={() => {
                      batch(() => {
                        dispatch(TableAction.setPlayPanelContent('Assets'));
                        dispatch(TableAction.setCurrentTab(RoomTab.Materials));
                      });
                    }}
                    className={clsx(
                      'button-reset',
                      styles.navButton,
                      currentTab === RoomTab.Materials && styles.isActive
                    )}
                  >
                    <BooksIcon />
                    <span>Materials</span>
                  </button>
                  <button
                    onClick={() => {
                      batch(() => {
                        dispatch(TableAction.setPlayPanelContent('Dice'));
                        dispatch(TableAction.setCurrentTab(RoomTab.Dice));
                      });
                    }}
                    className={clsx('button-reset', styles.navButton, currentTab === RoomTab.Dice && styles.isActive)}
                  >
                    <D20Icon />
                    <span>Dice</span>
                  </button>
                  {showStoreTab && (
                    <button
                      onClick={() => {
                        batch(() => {
                          dispatch(TableAction.setPlayPanelContent('Store'));
                          dispatch(TableAction.setCurrentTab(RoomTab.Store));
                        });
                      }}
                      className={clsx(
                        'button-reset',
                        styles.navButton,
                        currentTab === RoomTab.Store && styles.isActive
                      )}
                    >
                      <PriceTagIcon />
                      <span>Store</span>
                    </button>
                  )}
                </nav>
              </>
            )}
          </>
        ) : (
          <PageLoading />
        )}
      </MainLayout>
    </VideoProvider>
  );
}

export default PreviewPage;
