// @flow
import styles from './SheetBuilderPage.module.css';

import _ from 'lodash';
import type { Node } from 'react';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { v4 as uuid } from 'uuid';

import type {
  ElementModel,
  OnAddImage,
  OnChangeAvatar,
  OnChangeElement,
  OnChangeGroupElement,
  OnChangeSection,
  OnChangeSheet,
  OnRemoveImage,
  TemplateModel,
} from 'components/Sheet2/types';
import { ELEMENT_TYPE, INPUT_TYPE, LIBRARY } from 'constants/sheet';
import { PRIVACY } from 'constants/template';
import useQuery from 'hooks/useQuery';
import { DEFAULT_COLORS } from 'models/Color';
import { ErrorAction, SheetTemplateAction } from 'store/actions';
import { ErrorSelector, OrganizationSelector, SessionSelector, SheetTemplateSelector } from 'store/selectors';
import { getDate, insertItem, moveItem, nextUrl } from 'utilities';
import posthog from 'posthog-js';
import { createSection, deepCloneGroup, generateElement } from 'utilities/sheet.js';

import disableMobile from 'hocs/disableMobile';
import requireAuth from 'hocs/requireAuth';
import requireCurrentUser from 'hocs/requireCurrentUser';

import Button from 'components/buttons/Button';
import MainLayout from 'components/layouts/MainLayout';
import TitleBar from 'components/layouts/TitleBar';
import Metatags from 'components/Metatags';
import { LinkModal, TitleModal } from 'components/Modal';
import PageLoading from 'components/PageLoading';
import { AdvancedOptionsPanel, ElementLibrary, ElementLibraryItem, SectionBuilder, Template } from 'components/Sheet2';
import { BuilderComponents } from 'components/Sheet2/elements/ElementComponents';
import ToggleSwitch from 'components/ToggleSwitch';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import { ReactComponent as SingleArrowIcon } from 'images/icons/SingleArrowIcon.svg';

type Props = {
  colors: string[],
  model: TemplateModel,
  onAddImage: OnAddImage,
  onChange: OnChangeSheet,
  onChangeAvatar: OnChangeAvatar,
  onChangeElement: OnChangeElement,
  onChangeGroupElement: OnChangeGroupElement,
  onChangeSection: OnChangeSection,
  onRemoveImage: OnRemoveImage,
  onPublish: (guidSlug: string, callback: ?() => void) => void,
  onUnpublish: (guidSlug: string, callback: ?() => void) => void,
};

export const getElementLinkName = (element, section) => {
  /**TODO
   Handle "nearby" heading case
   Handle dice case
   */
  const labelInput = element.items.find((i) => i.inputType === INPUT_TYPE.heading);
  if (labelInput) {
    return labelInput.value ?? labelInput.defaultValue;
  } else {
    const elementIndex =
      section.elements.findIndex((e) => {
        if (e.elementType === ELEMENT_TYPE.group) {
          return e.items.findIndex((o) => o.id === element.id);
        } else {
          return e.id === element.id;
        }
      }) + 1;
    return `${section.title} ${LIBRARY[element.elementType].title} ${elementIndex}`;
  }
};

export function PureSheetBuilderPage(props: Props): Node {
  const {
    colors,
    model,
    model: {
      guid,
      guidSlug,
      draftData,
      images,
      hasPaidAncestor,
      isPublished,
      lastPublishedAt,
      name,
      privacy,
      ownerName,
    },
    onAddImage,
    onChangeAvatar,
    onChange,
    onChangeSection,
    onChangeElement,
    onChangeGroupElement,
    onPublish,
    onUnpublish,
    onRemoveImage,
  } = props;
  const tocMeta = { name, username: ownerName };
  const { sections } = draftData;
  const builderSectionRefs = useRef({});

  const history = useHistory();

  const query = useQuery();
  const fromSearch = query.get('from_search') === 'true';

  const [advancedOptions, setAdvancedOptions] = useState(null);
  const [confirmRemoveElement, setConfirmRemoveElement] = useState(null);
  const [confirmRemoveSection, setConfirmRemoveSection] = useState(null);
  const [confirmUngroupElement, setConfirmUngroupElement] = useState(null);
  const [confirmPublish, setConfirmPublish] = useState(false);
  const [confirmUnpublish, setConfirmUnpublish] = useState(false);
  const [isTOCOpen, setIsTOCOpen] = useState(true);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isUnpublishing, setIsUnpublishing] = useState(false);
  const [modalOptions, setModalOptions] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  // This is a workaround to allow for nested droppables.
  // see https://github.com/atlassian/react-beautiful-dnd/issues/860
  const [groupSectionOffsetTop, setGroupSectionOffsetTop] = useState('0');

  const sectionBodyRef = useRef(null);

  useEffect(() => {
    const logHeartbeat = () => {
      posthog.capture('template - session length heartbeat', {
        'template published': isPublished,
      });
    };
    logHeartbeat();
    const heartbeatTimer = setInterval(logHeartbeat, 300000);

    return () => {
      clearInterval(heartbeatTimer);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const top = groupSectionOffsetTop.split('_')[1];
    sectionBodyRef.current.scroll({ top: top, behavior: 'instant' });
  }, [groupSectionOffsetTop]);

  const forceContextRerender = (sectionId: string, groupId: string) => {
    const top = builderSectionRefs.current[sectionId].offsetTop;
    setGroupSectionOffsetTop(`${groupId}_${top}`);
  };

  const funPlaceholder = useCallback(() => {}, []);

  const onAddSection = (index: number) => {
    const newSection = createSection(uuid());
    const newSections = insertItem(sections, index + 1, newSection);
    const newModel = {
      ...model,
      draftData: {
        ...model.draftData,
        sections: newSections,
      },
    };
    onChange(newModel);
  };

  const onCloneElement = useCallback(
    (sectionId: string, elementId: string, groupId?: string) => {
      const section = sections.find((o) => o.id === sectionId);
      if (!section) return;
      if (groupId) {
        const groupElement = section.elements.find((e) => e.id === groupId);
        if (!groupElement) return;
        const oldElementIndex = groupElement.items.findIndex((e) => e.id === elementId);
        const element = groupElement.items[oldElementIndex];
        const cloneElement = {
          ...element,
          items: element.items.map((i) => ({ ...i, id: uuid() })),
          id: uuid(),
        };
        const newGroupItems = insertItem(groupElement.items, oldElementIndex + 1, cloneElement);
        onChangeElement(guid, sectionId, { ...groupElement, items: newGroupItems });
      } else {
        const oldElementIndex = section.elements.findIndex((e) => e.id === elementId);
        const element = section.elements[oldElementIndex];
        let cloneElement;
        if (element.elementType === ELEMENT_TYPE.group) {
          cloneElement = deepCloneGroup(element);
        } else {
          cloneElement = {
            ...element,
            items: element.items.map((i) => ({ ...i, id: uuid() })),
            id: uuid(),
          };
        }
        if (!cloneElement) return;
        const newElements = insertItem(section.elements, oldElementIndex + 1, cloneElement);
        const newSection = { ...section, elements: newElements };
        onChangeSection(guid, newSection);
      }
    },
    [guid, onChangeElement, onChangeSection, sections]
  );

  const onConfirmRemoveElement = useCallback((sectionId, elementId, groupId) => {
    setAdvancedOptions(null);
    setConfirmRemoveElement({ sectionId, elementId, groupId });
  }, []);

  const onConfirmRemoveSection = useCallback((sectionId) => {
    setAdvancedOptions(null);
    setConfirmRemoveSection({ sectionId });
  }, []);

  const onConfirmUngroupElement = useCallback((sectionId, elementId) => {
    setAdvancedOptions(null);
    setConfirmUngroupElement({ sectionId, elementId });
  }, []);

  const onMoveSection = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      const updatedModel = {
        ...model,
        draftData: {
          ...draftData,
          sections: moveItem(draftData.sections, sourceIndex, destinationIndex),
        },
      };
      onChange(updatedModel);
    },
    [draftData, model, onChange]
  );

  const onBeforeCapture = useCallback((before) => {
    setAdvancedOptions(null);
    setIsDragging(true); // this should apply a pointer-events: false to builder elements AND all group droppables to disabled
  }, []);

  const onDragEnd = useCallback(
    (result) => {
      const getSectionAndElement = (id: string) => {
        let section, element;
        section = sections.find((section) => {
          element = section.elements.find((element) => element.id === id);
          return element;
        });
        return { section, element };
      };
      setIsDragging(false);
      if (!result.destination) {
        return;
      }
      const sourceIndex = result.source.index;
      const destinationIndex = result.destination?.index;
      const sourceParentId = result.source.droppableId;
      const destinationParentId = result.destination?.droppableId;
      const sectionElementMap = sections.reduce((acc, section) => {
        acc[section.id] = section.elements;
        return acc;
      }, {});

      const sourceElements = sectionElementMap[sourceParentId];
      const destinationElements = sectionElementMap[destinationParentId];

      // Moving Elements/Builders
      if (sourceParentId === 'element-library') {
        // Elements Library Use Case
        const freshElement = generateElement(result.draggableId);

        if (destinationParentId?.startsWith(ELEMENT_TYPE.group)) {
          if (result.draggableId === ELEMENT_TYPE.group) return; // block-nested-groups
          const groupId = destinationParentId?.substring(ELEMENT_TYPE.group.length);
          const { section: destinationSection, element: destinationGroup } = getSectionAndElement(groupId);
          if (!destinationSection || !destinationGroup) return;
          const newItems = insertItem(destinationGroup.items, destinationIndex, freshElement);
          onChangeElement(guid, destinationSection.id, { ...destinationGroup, items: newItems });
        } else {
          if (result.draggableId === ELEMENT_TYPE.group) forceContextRerender(destinationParentId, freshElement.id);
          const sectionId = destinationParentId;
          const newSection = sections.find((o) => o.id === sectionId);
          if (!newSection) return;
          newSection.elements = insertItem(newSection.elements, destinationIndex, freshElement);
          onChangeSection(guid, newSection);
        }
      } else {
        // Moving Inside Builder Panel
        if (destinationParentId.startsWith(ELEMENT_TYPE.group) && sourceParentId.startsWith(ELEMENT_TYPE.group)) {
          // group to group
          if (sourceParentId === destinationParentId) {
            const groupId = destinationParentId.substring(ELEMENT_TYPE.group.length);
            const { section: destinationSection, element: groupElement } = getSectionAndElement(groupId);
            if (!destinationSection || !groupElement) return;
            const updatedDestinationItems = moveItem(groupElement.items, sourceIndex, destinationIndex);
            onChangeElement(guid, destinationSection.id, { ...groupElement, items: updatedDestinationItems });
          } else {
            const sourceGroupId = sourceParentId.substring(ELEMENT_TYPE.group.length);
            const destinationGroupId = destinationParentId.substring(ELEMENT_TYPE.group.length);
            const { section: sourceSection, element: sourceGroup } = getSectionAndElement(sourceGroupId);
            const { section: destinationSection, element: destinationGroup } = getSectionAndElement(destinationGroupId);
            if (!sourceSection || !sourceGroup || !destinationSection || !destinationGroup) return;

            const updatedSourceItems = [...sourceGroup.items];
            const [draggedElement] = updatedSourceItems.splice(sourceIndex, 1);
            const updatedDestinationItems = insertItem(destinationGroup.items, destinationIndex, draggedElement);
            // update source, then updated destination
            batch(() => {
              onChangeElement(guid, sourceSection.id, { ...sourceGroup, items: updatedSourceItems });
              onChangeElement(guid, destinationSection.id, { ...destinationGroup, items: updatedDestinationItems });
            });
          }
        } else if (sourceParentId.startsWith(ELEMENT_TYPE.group)) {
          // group to section
          const groupId = sourceParentId.substring(ELEMENT_TYPE.group.length);
          const { section: sourceSection, element: groupElement } = getSectionAndElement(groupId);
          if (!sourceSection || !groupElement) return;
          const updatedSourceItems = [...groupElement.items];
          const [draggedElement] = updatedSourceItems.splice(sourceIndex, 1);

          let newSections = [...sections];
          newSections = newSections.map((section) => {
            let newSection = { ...section };
            if (section.id === sourceSection.id) {
              // update Group to with filtered items
              const updatedElements = newSection.elements.map((e) =>
                e.id === groupId ? { ...e, items: updatedSourceItems } : e
              );
              newSection = { ...newSection, elements: updatedElements };
            }
            if (section.id === destinationParentId) {
              // update section with new element
              const newDestinationElements = insertItem(newSection.elements, destinationIndex, draggedElement);
              newSection = { ...newSection, elements: newDestinationElements };
            }
            return newSection;
          });
          const newModel = {
            ...model,
            draftData: {
              ...model.draftData,
              sections: newSections,
            },
          };
          onChange(newModel);
        } else if (destinationParentId.startsWith(ELEMENT_TYPE.group)) {
          // section to group
          let newSections = [...sections];
          let newSourceElements = [...sourceElements];
          const [draggedElement] = newSourceElements.splice(sourceIndex, 1);
          if (draggedElement.elementType === ELEMENT_TYPE.group) return; // block-nested-groups
          const groupId = destinationParentId.substring(ELEMENT_TYPE.group.length);
          const { section: destinationSection, element: groupElement } = getSectionAndElement(groupId);
          if (!destinationSection || !groupElement) return;
          const updatedDestinationItems = insertItem(groupElement.items, destinationIndex, draggedElement);

          newSections = newSections.map((section) => {
            let newSection = { ...section };
            if (section.id === sourceParentId) {
              // update section with filtered elements
              newSection = { ...newSection, elements: newSourceElements };
            }
            if (section.id === destinationSection.id) {
              // update Group to with new element
              const updatedElements = newSection.elements.map((e) =>
                e.id === groupId ? { ...e, items: updatedDestinationItems } : e
              );
              newSection = { ...newSection, elements: updatedElements };
            }
            return newSection;
          });
          const newModel = {
            ...model,
            draftData: {
              ...model.draftData,
              sections: newSections,
            },
          };
          onChange(newModel);
        } else if (sourceParentId === destinationParentId) {
          // Case where you reorder within the same section
          const reorderedElements = moveItem(sourceElements, sourceIndex, destinationIndex);
          const newSection = { ...sections.find((section) => section.id === sourceParentId) };
          newSection.elements = reorderedElements;

          onChangeSection(guid, newSection);
        } else {
          // Case where you reorder between sections
          let newSections = [...sections];
          let newSourceElements = [...sourceElements];
          const [draggedElement] = newSourceElements.splice(sourceIndex, 1);

          let newDestinationElements = [...destinationElements];
          newDestinationElements.splice(destinationIndex, 0, draggedElement);
          newSections = newSections.map((section) => {
            if (section.id === sourceParentId) {
              section.elements = newSourceElements;
            } else if (section.id === destinationParentId) {
              section.elements = newDestinationElements;
            }
            return section;
          });

          const newModel = {
            ...model,
            draftData: {
              ...model.draftData,
              sections: newSections,
            },
          };
          onChange(newModel);
        }
      }
    },
    [guid, model, onChange, onChangeElement, onChangeSection, sections]
  );

  const onPublishClick = () => {
    setConfirmPublish(true);
  };

  const onPublishConfirmClick = () => {
    if (isPublishing) return;
    setIsPublishing(true);
    onPublish(guidSlug, () => {
      posthog.capture(`template - ${isPublished ? 'republished' : 'published'}`);
      onPublishDismiss();
    });
  };

  const onPublishDismiss = () => {
    setConfirmPublish(false);
    setIsPublishing(false);
  };

  const onUnpublishClick = () => {
    setConfirmUnpublish(true);
  };

  const onUnpublishConfirmClick = () => {
    if (isUnpublishing) return;
    setIsUnpublishing(true);
    onUnpublish(guidSlug, () => {
      posthog.capture(`template - unpublished`);
      onUnpublishDismiss();
    });
  };

  const onUnpublishDismiss = () => {
    setConfirmUnpublish(false);
    setIsUnpublishing(false);
  };

  const onRemoveElement = () => {
    if (!confirmRemoveElement) return;
    const { sectionId, elementId, groupId } = confirmRemoveElement;
    setConfirmRemoveElement(null);
    const section = sections.find((o) => o.id === sectionId);
    if (!section) return;
    if (groupId) {
      const groupElement = section.elements.find((e) => e.id === groupId);
      if (!groupElement) return;
      const newGroupItems = groupElement.items.filter((o) => o.id !== elementId);
      onChangeElement(guid, sectionId, { ...groupElement, items: newGroupItems });
    } else {
      const newElements = section.elements.filter((element) => element.id !== elementId);
      const newSection = { ...section, elements: newElements };
      onChangeSection(guid, newSection);
    }
  };

  const onRemoveSection = () => {
    if (!confirmRemoveSection) return;
    const { sectionId } = confirmRemoveSection;
    setConfirmRemoveSection(null);
    let newSections = sections.filter((s) => s.id !== sectionId);
    if (newSections.length < 1) {
      const newSection = createSection(uuid());
      newSections = insertItem(newSections, 0, newSection);
    }
    const newModel = {
      ...model,
      draftData: {
        ...model.draftData,
        sections: newSections,
      },
    };
    onChange(newModel);
  };

  const onUngroupElement = () => {
    if (!confirmUngroupElement) return;
    const { sectionId, elementId } = confirmUngroupElement;
    setConfirmUngroupElement(null);
    const section = sections.find((o) => o.id === sectionId);
    if (!section) return;
    const groupElementIndex = section.elements.findIndex((e) => e.id === elementId);
    if (groupElementIndex < 0) return;
    const innerElements = [...section.elements[groupElementIndex].items];
    const newElements = section.elements.filter((e) => e.id !== elementId);
    newElements.splice(groupElementIndex, 0, ...innerElements);
    const newSection = { ...section, elements: newElements };
    onChangeSection(guid, newSection);
  };

  const setInnerRef = useCallback((el, id, innerRef) => innerRef(el), []);

  const renderDraggableBuilder = (element: ElementModel, sectionId: string, index: number) => {
    const BuilderComponent = BuilderComponents[element.elementType];
    const allowImageProps = element.elementType === ELEMENT_TYPE.image || element.elementType === ELEMENT_TYPE.group;
    return (
      <Draggable key={element.id} draggableId={element.id} index={index}>
        {(provided) => (
          <BuilderComponent
            colors={colors}
            element={element}
            images={allowImageProps ? images : null}
            key={element.id}
            onAdvancedPanelOpen={setAdvancedOptions}
            onChange={onChangeElement}
            onChangeGroup={onChangeGroupElement}
            onClone={onCloneElement}
            onRemove={onConfirmRemoveElement}
            onUngroup={onConfirmUngroupElement}
            onAddImage={allowImageProps ? onAddImage : null}
            onRemoveImage={allowImageProps ? onRemoveImage : null}
            provided={provided}
            setInnerRef={setInnerRef}
            sectionId={sectionId}
            sheetGuid={guid}
            isDragging={isDragging}
          />
        )}
      </Draggable>
    );
  };

  const onChangePrivacy = (checked: boolean) => {
    onChange({ ...model, privacy: checked ? PRIVACY.public : PRIVACY.unlisted });
  };

  const renderPublishModal = () => {
    return (
      <TitleModal
        title="Publish Now?"
        subtitle={
          isPublished && lastPublishedAt ? (
            <>
              The changes in your latest draft will be published on Role. Anyone who has this template saved to their
              library will immediately receive the latest version. This will overwite your last published version on{' '}
              <em>{getDate(new Date(lastPublishedAt))}</em>.
            </>
          ) : (
            'The changes in your latest draft will be published on Role. Once published you can use this template in your tables and share it with others!'
          )
        }
        actions={
          <>
            <Button variant="cancel" onClick={onPublishDismiss}>
              Cancel
            </Button>
            <Button variant="primary" isLoading={isPublishing} onClick={onPublishConfirmClick}>
              Publish
            </Button>
          </>
        }
        onDismiss={onPublishDismiss}
      >
        <div className={styles.modalPrivacyMessage}>
          <ToggleSwitch
            label="Public"
            checked={privacy === PRIVACY.public}
            disabled={hasPaidAncestor}
            colors={colors}
            onChange={onChangePrivacy}
          />
          {hasPaidAncestor && (
            <span>
              Templates duplicated from paid products cannot be made public. You can still share your template with
              players in your rooms.
            </span>
          )}
          {!hasPaidAncestor && (
            <span>
              {privacy === PRIVACY.public ? (
                <>
                  Your template is <strong>public</strong> and will be discoverable by other people on Role.
                </>
              ) : (
                'Your template is not public and is not discoverable by other people. You can still share your template with players in your rooms or via its share link.'
              )}
            </span>
          )}
        </div>
      </TitleModal>
    );
  };

  const renderUnpublishModal = () => {
    return (
      <TitleModal
        title="Unpublish Now?"
        subtitle="Your template will be unpublished from Role. Once unpublished, you can no longer use this template in your rooms or share it with others."
        actions={
          <>
            <Button variant="cancel" onClick={onUnpublishDismiss}>
              Cancel
            </Button>
            <Button variant="primary" isLoading={isPublishing} onClick={onUnpublishConfirmClick}>
              Unpublish
            </Button>
          </>
        }
        onDismiss={onUnpublishDismiss}
      />
    );
  };

  const renderRemoveElementModal = () => {
    return (
      <TitleModal
        title="Delete Element"
        subtitle="This element will be permanently removed from your Template."
        actions={
          <>
            <Button variant="cancel" onClick={() => setConfirmRemoveElement(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onRemoveElement}>
              Delete
            </Button>
          </>
        }
        onDismiss={() => setConfirmRemoveElement(null)}
      />
    );
  };

  const renderRemoveSectionModal = () => {
    return (
      <TitleModal
        title="Delete Section"
        subtitle="This section and all of its elements will be permanently removed from your Template."
        actions={
          <>
            <Button variant="cancel" onClick={() => setConfirmRemoveSection(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onRemoveSection}>
              Delete
            </Button>
          </>
        }
        onDismiss={() => setConfirmRemoveSection(null)}
      />
    );
  };

  const renderUngroupElementModal = () => {
    return (
      <TitleModal
        title="Ungroup Element"
        subtitle="Elements in this group will be moved into the section and the Group will be permanently removed from your Template."
        actions={
          <>
            <Button variant="cancel" onClick={() => setConfirmUngroupElement(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onUngroupElement}>
              Confirm
            </Button>
          </>
        }
        onDismiss={() => setConfirmUngroupElement(null)}
      />
    );
  };

  const elementLibraryRenderClone = useCallback((provided, snapshot, rubric) => {
    const item = LIBRARY[rubric.draggableId];
    const style = {};
    style.height = 182;
    style.width = item.columns === 1 ? 221 : 460;
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={{ ...provided.draggableProps.style, ...style }}
      >
        <ElementLibraryItem item={item} isDragging={true} style={style} />
      </div>
    );
  }, []);

  const navigateBackIfSearch = useCallback(
    (event) => {
      if (fromSearch) {
        event.preventDefault();
        history.goBack();
      }
    },
    [history, fromSearch]
  );

  return (
    <MainLayout
      navChildren={
        <TitleBar
          title={model.name}
          subtitle="Create & Customize Sheet Templates"
          leftChildren={
            <Button
              icon={<SingleArrowIcon style={{ transform: 'rotate(90deg)' }} />}
              isSimpleIcon
              iconSize={10}
              isExternalLink
              to={nextUrl('/dashboard/templates')}
              target="_self"
              onClick={navigateBackIfSearch}
              color="var(--color-text-grey)"
            >
              {fromSearch ? 'Search Results' : 'Your Templates'}
            </Button>
          }
        />
      }
    >
      <Metatags title={`${model.name} | Template Creator`} />
      <div className={styles.container}>
        <div className={`${styles.content} ${isTOCOpen ? styles.isTocOpen : ''}`}>
          <Template
            className={styles.template}
            model={model}
            tocMeta={tocMeta}
            colors={colors}
            canEdit
            isDraft
            isTOCOpen={isTOCOpen}
            onChange={onChange}
            onChangeAvatar={onChangeAvatar}
            onChangeElement={funPlaceholder}
            onChangeGroupElement={funPlaceholder}
            onChangeSection={funPlaceholder}
            onChangeTOCOpen={setIsTOCOpen}
            onPublishClick={onPublishClick}
            onUnpublishClick={onUnpublishClick}
            showMenu={isPublished && !!onUnpublish}
          />
          <div className={styles.builderContainer}>
            <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd} key={groupSectionOffsetTop}>
              <div ref={(el) => (sectionBodyRef.current = el)} className={`scrollbars-dark ${styles.builder}`}>
                {sections.map((section, index) => (
                  <Fragment key={index}>
                    <SectionBuilder
                      index={index}
                      isLastSection={index === sections.length - 1}
                      key={section.id}
                      onChangeSection={onChangeSection}
                      onMoveSection={onMoveSection}
                      onRemove={onConfirmRemoveSection}
                      refs={builderSectionRefs}
                      section={section}
                      sheetGuid={guid}
                    >
                      {section.elements?.map((element, index) => renderDraggableBuilder(element, section.id, index))}
                    </SectionBuilder>
                    <Button
                      className={styles.addSectionButton}
                      variant="primary"
                      onClick={() => onAddSection(index)}
                      icon={<PlusIcon />}
                    >
                      Add Section
                    </Button>
                  </Fragment>
                ))}
              </div>
              {advancedOptions ? (
                <div className={`scrollbars-dark ${styles.library}`}>
                  <AdvancedOptionsPanel
                    sheetGuid={guid}
                    sectionId={advancedOptions.sectionId}
                    elementId={advancedOptions.elementId}
                    groupId={advancedOptions.groupId}
                    onClose={() => setAdvancedOptions(null)}
                    onChangeElement={onChangeElement}
                    onChangeGroupElement={onChangeGroupElement}
                    renderLinkModal={setModalOptions}
                  />
                </div>
              ) : (
                <Droppable droppableId="element-library" isDropDisabled={true} renderClone={elementLibraryRenderClone}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`scrollbars-dark ${styles.library}`}
                    >
                      <ElementLibrary />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
            </DragDropContext>
          </div>
        </div>
        {modalOptions && (
          <LinkModal
            actionType={modalOptions?.actionType}
            elementId={advancedOptions.elementId}
            groupId={advancedOptions.groupId}
            link={modalOptions.link ?? null}
            linkIndex={modalOptions.linkIndex ?? null}
            linkType={modalOptions.linkType}
            onChangeElement={onChangeElement}
            onChangeGroupElement={onChangeGroupElement}
            onModalClose={() => setModalOptions(null)}
            sectionId={advancedOptions.sectionId}
            sheetGuid={guid}
          />
        )}
      </div>
      {!!confirmRemoveElement && renderRemoveElementModal()}
      {!!confirmRemoveSection && renderRemoveSectionModal()}
      {!!confirmUngroupElement && renderUngroupElementModal()}
      {confirmPublish && renderPublishModal()}
      {confirmUnpublish && renderUnpublishModal()}
    </MainLayout>
  );
}

function SheetBuilderPage(props) {
  const {
    match: {
      params: { guidSlug },
    },
    history,
  } = props;

  const dispatch = useDispatch();
  const model = useSelector((state) => SheetTemplateSelector.get(state, guidSlug));
  const currentUser = useSelector(SessionSelector.currentUser);
  const org = useSelector((state) =>
    model?.ownerType === 'Organization' ? OrganizationSelector.getById(state, model.ownerId) : null
  );
  const fetchError = useSelector((state) => ErrorSelector.get(state, SheetTemplateAction.FETCH));
  const notOwner = model && model.ownerType === 'User' && model.ownerId !== currentUser.id;
  const notOrgMember =
    model && model.ownerType === 'Organization' && org && !currentUser.organizationIds.includes(org.id);

  useEffect(() => {
    if (fetchError || (!currentUser.isAdmin && (notOwner || notOrgMember))) history.push('/sheet-templates');
    return () => {
      dispatch(ErrorAction.remove(SheetTemplateAction.FETCH));
    };
  }, [currentUser.isAdmin, dispatch, fetchError, history, notOrgMember, notOwner]);

  useEffect(() => {
    if (!model) dispatch(SheetTemplateAction.fetch(guidSlug));
    // eslint-disable-next-line
  }, []);

  const debouncedUpdate = useMemo(
    () => _.debounce((model, callback = null) => dispatch(SheetTemplateAction.updateAndForget(model, callback)), 300),
    [dispatch]
  );

  const debouncedUpdateElement = useMemo(
    () =>
      _.debounce(
        (id, sectionId, element, callback = null) =>
          dispatch(SheetTemplateAction.updateElement(id, sectionId, element, callback)),
        300
      ),
    [dispatch]
  );

  const debouncedUpdateGroupElement = useMemo(
    () =>
      _.debounce(
        (id, sectionId, groupId, element, callback = null) =>
          dispatch(SheetTemplateAction.updateGroupElement(id, sectionId, groupId, element, callback)),
        300
      ),
    [dispatch]
  );

  const debouncedUpdateSection = useMemo(
    () =>
      _.debounce(
        (id, section, callback = null) => dispatch(SheetTemplateAction.updateSection(id, section, callback)),
        300
      ),
    [dispatch]
  );

  const onAddImage = useCallback(
    (id, sectionId, elementId, groupElementId, image, callback = null) => {
      if (image) dispatch(SheetTemplateAction.addImage(id, sectionId, elementId, groupElementId, image, callback));
    },
    [dispatch]
  );

  const onChange = useCallback(
    (model, callback = null) => {
      batch(() => {
        dispatch(SheetTemplateAction.change(model));
        debouncedUpdate(model, callback);
      });
    },
    [debouncedUpdate, dispatch]
  );

  const onChangeAvatar = useCallback(
    (id, file, callback = null) => {
      if (file) dispatch(SheetTemplateAction.updateAvatar(id, file, callback));
      else dispatch(SheetTemplateAction.removeAvatar(id, callback));
    },
    [dispatch]
  );

  const onChangeElement = useCallback(
    (id, sectionId, element, callback = null) => {
      batch(() => {
        dispatch(SheetTemplateAction.changeElement(id, sectionId, element));
        debouncedUpdateElement(id, sectionId, element, callback);
      });
    },
    [debouncedUpdateElement, dispatch]
  );

  const onChangeGroupElement = useCallback(
    (id, sectionId, groupId, element, callback = null) => {
      batch(() => {
        dispatch(SheetTemplateAction.changeGroupElement(id, sectionId, groupId, element));
        debouncedUpdateGroupElement(id, sectionId, groupId, element, callback);
      });
    },
    [debouncedUpdateGroupElement, dispatch]
  );

  const onChangeSection = useCallback(
    (id, section, callback = null) => {
      batch(() => {
        dispatch(SheetTemplateAction.changeSection(id, section));
        debouncedUpdateSection(id, section, callback);
      });
    },
    [debouncedUpdateSection, dispatch]
  );

  const onPublish = useCallback(
    (guidSlug, callback = null) => {
      dispatch(SheetTemplateAction.publish(guidSlug, callback));
    },
    [dispatch]
  );

  const onUnpublish = useCallback(
    (guidSlug, callback = null) => {
      dispatch(SheetTemplateAction.unpublish(guidSlug, callback));
    },
    [dispatch]
  );

  const onRemoveImage = useCallback(
    (id, sectionId, elementId, groupElementId, imageId, callback = null) => {
      if (imageId)
        dispatch(SheetTemplateAction.removeImage(id, sectionId, elementId, groupElementId, imageId, callback));
    },
    [dispatch]
  );

  return model ? (
    <PureSheetBuilderPage
      model={model}
      colors={DEFAULT_COLORS}
      onChange={onChange}
      onChangeAvatar={onChangeAvatar}
      onChangeElement={onChangeElement}
      onChangeGroupElement={onChangeGroupElement}
      onChangeSection={onChangeSection}
      onPublish={onPublish}
      onUnpublish={onUnpublish}
      onAddImage={onAddImage}
      onRemoveImage={onRemoveImage}
    />
  ) : (
    <PageLoading />
  );
}

export default compose(requireAuth, requireCurrentUser, disableMobile)(SheetBuilderPage);
