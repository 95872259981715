import _ from 'lodash';

import { createAction, handleError } from './utilities';
import { SessionSelector, SheetSelector } from 'store/selectors';
import posthog from 'posthog-js';

import role from 'apis/role';

export const extractObj = (data) => {
  const {
    id,
    attributes,
    relationships: { playkits, products, games },
  } = data;
  const playkitIds = playkits.data.map((o) => o.id);
  const productIds = products.data.map((o) => o.id);
  const gameIds = games.data.map((o) => o.id);
  return { id, ...attributes, playkitIds, productIds, gameIds };
};

class SheetAction {
  static CONFIRM_TRANSFER = 'SheetAction.CONFIRM_TRANSFER';
  static COPY = 'SheetAction.COPY';
  static CREATE = 'SheetAction.CREATE';
  static DELETE = 'SheetAction.DELETE';
  static EDIT = 'SheetAction.EDIT';
  static FETCH = 'SheetAction.FETCH';
  static FETCH_ALL = 'SheetAction.FETCH_ALL';
  static INPUT_BLURRED = 'SheetAction.INPUT_BLURRED';
  static INPUT_FOCUSED = 'SheetAction.INPUT_FOCUSED';
  static REMOVE = 'SheetAction.REMOVE';
  static REQUEST_REMOVE = 'SheetAction.REQUEST_REMOVE';
  static REQUEST_UPDATE = 'SheetAction.REQUEST_UPDATE';
  static SHOW_MENU = 'SheetAction.SHOW_MENU';
  static TRANSFER = 'SheetAction.TRANSFER';
  static UPDATE = 'SheetAction.UPDATE';
  static UPDATE_INPUT = 'SheetAction.UPDATE_INPUT';
  static UPDATE_LOCAL = 'SheetAction.UPDATE_LOCAL';
  static UPDATE_LOCAL_INPUT = 'SheetAction.UPDATE_LOCAL_INPUT';
  static UPDATE_TEMPLATE = 'SheetAction.UPDATE_TEMPLATE';

  static UPDATE_AND_FORGET = 'SheetAction.UPDATE_AND_FORGET';
  static UPDATE_ELEMENT = 'SheetAction.UPDATE_ELEMENT';
  static UPDATE_GROUP_ELEMENT = 'SheetAction.UPDATE_GROUP_ELEMENT';
  static UPDATE_SECTION = 'SheetAction.UPDATE_SECTION';

  static CHANGE = 'SheetAction.CHANGE';
  static CHANGE_ELEMENT = 'SheetAction.CHANGE_ELEMENT';
  static CHANGE_GROUP_ELEMENT = 'SheetAction.CHANGE_GROUP_ELEMENT';
  static CHANGE_SECTION = 'SheetAction.CHANGE_SECTION';

  static confirmTransfer = (id) => {
    return createAction(SheetAction.CONFIRM_TRANSFER, id);
  };

  static copy = (id, callback) => async (dispatch) => {
    return role.post(`/sheets/${id}/copy`).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetAction.COPY, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetAction.COPY, response)
    );
  };

  static create =
    (sheet, callback = null, failureCallback = null) =>
    async (dispatch, getState) => {
      const data = { ...sheet, socket_id: SessionSelector.getSocketId(getState()) };
      return role.post('/sheets', data).then(
        (response) => {
          const obj = extractObj(response.data.data);
          dispatch(createAction(SheetAction.CREATE, obj));
          posthog.capture('sheet - create sheet', {
            'template id': obj.sheetTemplateId,
            'has playkit': !_.isEmpty(sheet.playkit),
            'playkit id': sheet.playkit?.id,
            'playkit sheet id': sheet.playkit?.sheet_id,
            'from playkit template': !!sheet.is_playkit_template,
            'ddb id': obj.ddbId,
            'from game template': !!sheet.is_game_template,
            'game id': sheet.game_id,
            'game title': sheet.game_title,
            'product ids': sheet.product_ids,
            $set_once: {'ddbUser': !!obj.ddbId }
          });
          
          posthog.capture('sheet - create sheet', {
            'template id': obj.sheetTemplateId,
            'has playkit': !_.isEmpty(sheet.playkit),
            'playkit id': sheet.playkit?.id,
            'playkit sheet id': sheet.playkit?.sheet_id,
            'from playkit template': !!sheet.is_playkit_template,
            'ddb id': obj.ddbId,
            'from game template': !!sheet.is_game_template,
            'game id': sheet.game_id,
            'game title': sheet.game_title,
            'product ids': sheet.product_ids,
          });
          if (callback) callback(obj);
        },
        ({ response }) => {
          if (failureCallback) failureCallback(response);
          return handleError(dispatch, SheetAction.CREATE, response);
        }
      );
    };

  static delete = (id) => async (dispatch) => {
    return role.delete(`/sheets/${id}`).then(
      (response) => {
        dispatch(createAction(SheetAction.DELETE, id));
        posthog.capture('sheet - delete sheet');
      },
      ({ response }) => handleError(dispatch, SheetAction.DELETE, response)
    );
  };

  static edit = (id) => {
    return createAction(SheetAction.EDIT, id);
  };

  static fetch = (id, params, callback) => async (dispatch) => {
    return role.get(`/sheets/${id}`, { params }).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetAction.FETCH, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetAction.FETCH, response)
    );
  };

  static fetchAll = (params, callback) => async (dispatch) => {
    return role.get('/sheets', { params }).then(
      (response) => {
        const objs = response.data.data.map((obj) => {
          return extractObj(obj);
        });
        dispatch(createAction(SheetAction.FETCH_ALL, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, SheetAction.FETCH_ALL, response)
    );
  };

  static inputBlurred = (id, inputId) => {
    return createAction(SheetAction.INPUT_BLURRED, { id, inputId });
  };

  static inputFocused = (id, inputId) => {
    return createAction(SheetAction.INPUT_FOCUSED, { id, inputId });
  };

  static remove = (guid, id) => {
    return createAction(SheetAction.REMOVE, { guid, id });
  };

  static removeAvatar = (id, callback) => async (dispatch) => {
    return role.post(`/sheets/${id}/remove_avatar`).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetAction.UPDATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetAction.UPDATE, response)
    );
  };

  static showMenu = (id) => {
    return createAction(SheetAction.SHOW_MENU, id);
  };

  static transfer = (id, formValues, callback) => async (dispatch, getState) => {
    return role
      .post(`/sheets/${id}/transfer`, { ...formValues, socket_id: SessionSelector.getSocketId(getState()) })
      .then(
        (response) => {
          const obj = extractObj(response.data.data);
          dispatch(createAction(SheetAction.TRANSFER, obj));
          if (callback) callback(obj);
        },
        ({ response }) => handleError(dispatch, SheetAction.TRANSFER, response)
      );
  };

  static update = (id, formValues, callback) => async (dispatch, getState) => {
    return role.patch(`/sheets/${id}`, { ...formValues, socket_id: SessionSelector.getSocketId(getState()) }).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetAction.UPDATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetAction.UPDATE, response)
    );
  };

  static updateAndForget =
    (model, roomGuid = null, callback = null, type = SheetAction.UPDATE_AND_FORGET) =>
    async (dispatch, getState) => {
      const data = {
        sheet: { name: model.name, data: model.data },
        room_guid: roomGuid,
        socket_id: SessionSelector.getSocketId(getState()),
      };
      return role.patch(`/sheets/${model.id}`, data).then(
        (response) => {
          const obj = extractObj(response.data.data);
          dispatch(createAction(type, obj));
          if (callback) callback(obj);
        },
        ({ response }) => handleError(dispatch, type, response)
      );
    };

  static updateAvatar = (id, avatar, callback) => async (dispatch, getState) => {
    const formData = new FormData();
    if (avatar) formData.append('sheet[avatar]', avatar);
    formData.append('socket_id', SessionSelector.getSocketId(getState()));

    return role.patch(`/sheets/${id}`, formData).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetAction.UPDATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetAction.UPDATE, response)
    );
  };

  static updateInput =
    (id, sectionId, blockId, input, roomGuid = null) =>
    async (dispatch, getState) => {
      const data = { sheet: { section_id: sectionId, block_id: blockId, input }, room_guid: roomGuid };
      return role.patch(`/sheets/${id}/input`, { ...data, socket_id: SessionSelector.getSocketId(getState()) }).then(
        (response) => {
          const obj = extractObj(response.data.data);
          dispatch(createAction(SheetAction.UPDATE_INPUT, { sheet: obj, sectionId, blockId, input }));
        },
        ({ response }) => handleError(dispatch, SheetAction.UPDATE_INPUT, response)
      );
    };

  static updateLocal = (sheet) => {
    return createAction(SheetAction.UPDATE_LOCAL, sheet);
  };

  static updateLocalInput = (sheet, sectionId, blockId, input) => {
    return createAction(SheetAction.UPDATE_LOCAL_INPUT, { sheet, sectionId, blockId, input });
  };

  static updateTemplate = (id, callback) => async (dispatch, getState) => {
    return role.patch(`/sheets/${id}/template`, { socket_id: SessionSelector.getSocketId(getState()) }).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetAction.UPDATE_TEMPLATE, obj));
        callback?.(obj);
      },
      ({ response }) => handleError(dispatch, SheetAction.UPDATE_TEMPLATE, response)
    );
  };

  static change = (model) => {
    return createAction(SheetAction.CHANGE, model);
  };

  static changeElement = (id, sectionId, element) => {
    return createAction(SheetAction.CHANGE_ELEMENT, { id, sectionId, element });
  };

  static changeGroupElement = (id, sectionId, groupId, element) => {
    return createAction(SheetAction.CHANGE_GROUP_ELEMENT, { id, sectionId, groupId, element });
  };

  static changeSection = (id, section) => {
    return createAction(SheetAction.CHANGE_SECTION, { id, section });
  };

  static updateElement =
    (id, sectionId, element, roomGuid = null, callback = null) =>
    async (dispatch, getState) => {
      const model = SheetSelector.get(getState(), id);
      const updatedModel = {
        ...model,
        data: {
          ...model.data,
          sections: model.data.sections.map((o) => {
            if (o.id === sectionId) {
              return { ...o, elements: o.elements.map((e) => (e.id === element.id ? element : e)) };
            }
            return o;
          }),
        },
      };
      return dispatch(SheetAction.updateAndForget(updatedModel, roomGuid, callback, SheetAction.UPDATE_ELEMENT));
    };

  static updateGroupElement =
    (id, sectionId, groupId, element, roomGuid = null, callback = null) =>
    async (dispatch, getState) => {
      const model = SheetSelector.get(getState(), id);
      const updatedModel = {
        ...model,
        data: {
          ...model.data,
          sections: model.data.sections.map((o) => {
            if (o.id === sectionId) {
              return {
                ...o,
                elements: o.elements.map((e) => {
                  if (e.id === groupId) {
                    return { ...e, items: e.items.map((ge) => (ge.id === element.id ? element : ge)) };
                  }
                  return e;
                }),
              };
            }
            return o;
          }),
        },
      };
      return dispatch(SheetAction.updateAndForget(updatedModel, roomGuid, callback, SheetAction.UPDATE_GROUP_ELEMENT));
    };

  static updateSection =
    (id, section, roomGuid = null, callback = null) =>
    async (dispatch, getState) => {
      const model = SheetSelector.get(getState(), id);
      const updatedModel = {
        ...model,
        data: {
          ...model.data,
          sections: model.data.sections.map((o) => (o.id === section.id ? section : o)),
        },
      };
      return dispatch(SheetAction.updateAndForget(updatedModel, roomGuid, callback, SheetAction.UPDATE_SECTION));
    };

  /***
    UI Actions
   */

  static requestRemove = (id) => {
    return createAction(SheetAction.REQUEST_REMOVE, id);
  };

  static requestUpdate = (id) => {
    return createAction(SheetAction.REQUEST_UPDATE, id);
  };
}

export default SheetAction;
