// @flow
import styles from './TableSheet.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import type { Node } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { EDITOR_OPTIONS, PERMISSIONS, VIEWER_OPTIONS } from 'constants/room_sheet';
import useWindowSize from 'hooks/useWindowSize';
import { RoomSheetAction, SheetAction, SheetTemplateAction, TableAction } from 'store/actions/';
import {
  GameSelector,
  PlaykitSelector,
  RoomSheetSelector,
  RoomUserSelector,
  SessionSelector,
  SheetSelector,
  SheetTemplateSelector,
  TableSelector,
} from 'store/selectors';
import posthog from 'posthog-js';

import Empty from './Empty';
import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import InviteModal from 'components/InviteModal';
import { TitleModal } from 'components/Modal';
import RoomControls from 'components/RoomControls';
import Sheet from 'components/Sheet2';
import TableSheetCreator from 'components/TableSheet/TableSheetCreator';

function TableSheet({ room, isTOCOpen, onChangeTOCOpen }): Node {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const currentUser = useSelector(SessionSelector.currentUser);
  const [showRemoveSheetModal, setShowRemoveSheetModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const sheet = useSelector(TableSelector.getActiveSheet);
  const user = useSelector((state) => TableSelector.getActiveSheetUser(state, room.guid));
  const game = useSelector((state) => GameSelector.get(state, room.gameId));
  const playkits = useSelector((state) => PlaykitSelector.getByRoom(state, room.guid));
  const colorsUserId = sheet ? sheet.userId : user.userId;
  const colors = useSelector((state) => RoomUserSelector.getColors(state, room.guid, colorsUserId));
  const isAddingSheet = useSelector(TableSelector.isAddingSheet);
  const isOwner = user?.userId === currentUser.id;
  const isShowingInvite = useSelector(TableSelector.isShowingInvite);
  const isShowingSheet = useSelector(TableSelector.isShowingSheet);
  const isShowingSheetMenu = useSelector(TableSelector.isShowingSheetMenu);
  const previewSheet = useSelector(TableSelector.getPreviewSheet);
  const previewGuidSlug = previewSheet
    ? previewSheet.isTemplate
      ? previewSheet.sheet.guidSlug
      : previewSheet.sheet.sheetTemplateGuidSlug
    : null;
  const templateForPreviewSheet = useSelector((state) =>
    previewSheet && previewGuidSlug ? SheetTemplateSelector.get(state, previewGuidSlug) : null
  );
  const roomSheet = useSelector((state) => (sheet ? RoomSheetSelector.get(state, room.guid, sheet.id) : null));
  const viewers = useSelector((state) => (sheet ? RoomSheetSelector.getViewers(state, room.guid, sheet.id) : null));
  const editors = useSelector((state) => (sheet ? RoomSheetSelector.getEditors(state, room.guid, sheet.id) : null));

  const sheets = useSelector((state) =>
    isOwner
      ? SheetSelector.getByRoomAndUser(state, room.guid, user.userId)
      : SheetSelector.getViewableByRoomAndUser(state, room.guid, user.userId)
  );

  const template = useSelector((state) =>
    sheet && sheet.sheetTemplateGuidSlug ? SheetTemplateSelector.get(state, sheet.sheetTemplateGuidSlug) : null
  );
  const theme = useSelector((state) => TableSelector.getTheme(state, room.guid));
  const isEmpty = !sheet && !previewSheet && !isAddingSheet && !isOwner;
  const isViewingSheet = sheet && roomSheet && user && !isAddingSheet && !previewSheet;
  const isViewingTemplates = !previewSheet && (isAddingSheet || (!sheet && isOwner));
  const canEdit = useSelector((state) => RoomSheetSelector.canEdit(state, room.guid, roomSheet?.id, currentUser.id));
  let sheetStatus = previewSheet ? '' : '(Private)';
  if (roomSheet && !previewSheet && roomSheet.permissions.viewers?.includes(PERMISSIONS.everyone)) {
    if (roomSheet.permissions.editors?.includes(PERMISSIONS.everyone)) sheetStatus = '(Shared)';
    else sheetStatus = '(Public)';
  }
  const sheetDisplayName = `${user.displayName} ${sheetStatus}`;

  useEffect(() => {
    if (sheet) {
      if (sheet.ddbId) onChangeTOCOpen(false);
      return;
    }
    if (sheets.length > 0) {
      dispatch(TableAction.setActiveSheet(sheets[0].id));
    } else if (!previewSheet) {
      onChangeTOCOpen(false);
    }
  }, [sheet, sheets, previewSheet, onChangeTOCOpen, dispatch]);

  useEffect(() => {
    if (isViewingSheet && !sheet.data) {
      dispatch(SheetAction.fetch(sheet.id, { room_guid: room.guid }));
    }
  }, [dispatch, isViewingSheet, room.guid, sheet?.data, sheet?.id]);

  useEffect(() => {
    if (previewSheet && !previewSheet.sheet.data) {
      if (previewSheet.isTemplate) {
        dispatch(
          SheetTemplateAction.fetch(previewSheet.sheet.guidSlug, (sheet) => {
            dispatch(TableAction.setPreviewSheet({ ...previewSheet, sheet }));
          })
        );
      } else {
        dispatch(
          SheetAction.fetch(previewSheet.sheet.id, { room_guid: room.guid }, (sheet) => {
            dispatch(TableAction.setPreviewSheet({ ...previewSheet, sheet }));
          })
        );
      }
    }
  }, [dispatch, previewSheet, room.guid]);

  useEffect(() => {
    if (sheet && sheet.sheetTemplateGuidSlug && !template)
      dispatch(SheetTemplateAction.fetch(sheet.sheetTemplateGuidSlug));
  }, [dispatch, sheet, template]);

  const noop = () => {};

  const onAddSheetClick = () => {
    onChangeTOCOpen(false);
    dispatch(TableAction.addSheet(true));
  };

  const onChangeAvatar = (id, file, callback = null) => {
    if (file) dispatch(SheetAction.updateAvatar(id, file, callback));
    else dispatch(SheetAction.removeAvatar(id, callback));
  };

  const debouncedUpdateSheet = _.debounce((model, roomGuid, callback) =>
    dispatch(SheetAction.updateAndForget(model, roomGuid, callback), 300)
  );

  const onChange = (model, callback = null) => {
    batch(() => {
      dispatch(SheetAction.change(model));
      debouncedUpdateSheet(model, room.guid, callback);
    });
  };

  const debouncedUpdateSheetElement = _.debounce(
    (id, sectionId, element, roomGuid, callback) =>
      dispatch(SheetAction.updateElement(id, sectionId, element, roomGuid, callback)),
    300
  );

  const onChangeElement = (id, sectionId, element, callback = null) => {
    batch(() => {
      dispatch(SheetAction.changeElement(id, sectionId, element));
      debouncedUpdateSheetElement(id, sectionId, element, room.guid, callback);
    });
  };

  const debouncedUpdateSheetGroupElement = _.debounce(
    (id, sectionId, groupId, element, roomGuid, callback) =>
      dispatch(SheetAction.updateGroupElement(id, sectionId, groupId, element, roomGuid, callback)),
    300
  );

  const onChangeGroupElement = (id, sectionId, groupId, element, callback = null) => {
    batch(() => {
      dispatch(SheetAction.changeGroupElement(id, sectionId, groupId, element));
      debouncedUpdateSheetGroupElement(id, sectionId, groupId, element, room.guid, callback);
    });
  };

  const debouncedUpdateSheetSection = _.debounce(
    (id, section, roomGuid, callback) => dispatch(SheetAction.updateSection(id, section, roomGuid, callback)),
    300
  );

  const onChangeSection = (id, section, callback = null) => {
    batch(() => {
      dispatch(SheetAction.changeSection(id, section));
      debouncedUpdateSheetSection(id, section, room.guid, callback);
    });
  };

  const onDeleteSheetClick = () => {
    dispatch(
      RoomSheetAction.delete(room.guid, sheet.id, () => {
        dispatch(TableAction.setActiveSheetUser(sheet.userId));
        dispatch(TableAction.setActiveSheet(null));
      })
    );
    onRemoveSheetModalDismiss();
  };

  const onViewersDropdownChange = (option) => {
    const newPermissions = [];
    if (option.value === PERMISSIONS.host) newPermissions.push(room.userId);
    else if (option.value === PERMISSIONS.everyone) newPermissions.push(PERMISSIONS.everyone);
    const params = { room_sheet: { permissions: { ...roomSheet.permissions, viewers: newPermissions } } };
    dispatch(
      RoomSheetAction.update(room.guid, roomSheet.id, params, () => {
        posthog.capture('table - update permissions', { 'viewers': option.value });
      })
    );
  };

  const onEditorsDropdownChange = (option) => {
    const newPermissions = [];
    if (option.value === PERMISSIONS.host) newPermissions.push(room.userId);
    else if (option.value === PERMISSIONS.everyone) newPermissions.push(PERMISSIONS.everyone);
    const params = { room_sheet: { permissions: { ...roomSheet.permissions, editors: newPermissions } } };
    dispatch(
      RoomSheetAction.update(room.guid, roomSheet.id, params, () => {
        posthog.capture('table - update permissions', { 'editors': option.value });
      })
    );
  };

  const onPrimaryClick = () => {
    const is_primary = !roomSheet.isPrimary;
    dispatch(
      RoomSheetAction.update(room.guid, roomSheet.id, { room_sheet: { is_primary } }, () => {
        dispatch(RoomSheetAction.fetchAll(room.guid));
      })
    );
  };

  const onRemoveSheetClick = () => {
    setShowRemoveSheetModal(true);
    dispatch(TableAction.showSheetMenu(false));
  };

  const onRemoveSheetModalDismiss = () => {
    setShowRemoveSheetModal(false);
  };

  const onSettingsClick = () => {
    if (isShowingSheet) dispatch(TableAction.showSheetMenu(!isShowingSheetMenu));
  };

  const onToggleClick = useCallback(() => {
    dispatch(TableAction.showSheet(!isShowingSheet));
    if (!isShowingSheet) dispatch(TableAction.showSheetMenu(false));
    setIsCollapsed(!isCollapsed);
  }, [dispatch, isShowingSheet, isCollapsed]);

  const onTransferSheetClick = () => {
    dispatch(SheetAction.confirmTransfer(sheet.id));
    dispatch(TableAction.showSheetMenu(false));
  };

  const menuItems = [];
  if (isOwner) {
    menuItems.push({
      label: 'Viewers',
      dropdownOptions: VIEWER_OPTIONS,
      dropdownSelected: viewers,
      onDropdownChange: onViewersDropdownChange,
    });
    menuItems.push({
      label: 'Editors',
      dropdownOptions: EDITOR_OPTIONS,
      dropdownSelected: editors,
      onDropdownChange: onEditorsDropdownChange,
    });
    menuItems.push({
      label: 'Set as Primary',
      isActive: !!roomSheet && roomSheet.isPrimary,
      onToggle: onPrimaryClick,
    });
    menuItems.push({ label: 'Give to Player', onClick: onTransferSheetClick });
    menuItems.push({ label: 'Remove Sheet', onClick: onRemoveSheetClick });
  }

  const renderRemoveSheetModal = () => {
    return (
      <TitleModal
        title="Remove this Sheet?"
        subtitle="Do you want to remove this Sheet from this Room? It will remain saved in your account, and you can add it back later."
        onDismiss={onRemoveSheetModalDismiss}
      >
        <Avatar
          borderColors={colors}
          className={styles.modalAvatar}
          avatarUrl={sheet.avatarUrl ?? null}
          shape="squircle"
          size={144}
          isDdbSheet={!!sheet.ddbId}
          showSheetDefault
        />
        <h4 className={`heading1 ${styles.modalName}`}>{sheet.name}</h4>
        <div className={styles.modalActions}>
          <Button variant="cancel" onClick={onRemoveSheetModalDismiss}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDeleteSheetClick}>
            Remove
          </Button>
        </div>
      </TitleModal>
    );
  };

  const subtitle = () => {
    if (game) return game.title;
    else if (playkits.length > 0) return playkits[0].title;
    else return null;
  };

  return (
    <div className={clsx('box-shadow', styles.container, !isShowingSheet && styles.hideSheet)}>
      <div className={styles.noise} />

      <div className={styles.content}>
        {size.isMd && (
          <RoomControls
            subtitle={subtitle()}
            room={room}
            theme={theme}
            onToggleClick={onToggleClick}
            isTOCOpen={isTOCOpen}
            isShowingSheet={isShowingSheet}
            onAddSheet={onAddSheetClick}
          />
        )}

        {isEmpty && (
          <Empty
            className={clsx(!isShowingSheet && styles.hide)}
            onAddSheetClick={onAddSheetClick}
            user={user}
            isSelf={isOwner}
          />
        )}
        {isViewingSheet && sheet.data && (
          <Sheet
            className={clsx(styles.sheet, !isShowingSheet && styles.hideSheet, styles.tableSheet)}
            theme={theme}
            colors={colors}
            roomGuid={room.guid}
            model={sheet}
            tocMeta={template && { name: template.name, username: template.ownerName }}
            userName={sheetDisplayName}
            menuItems={menuItems.length > 0 ? menuItems : null}
            isCollapsed={!isShowingSheet}
            isMenuActive={isShowingSheetMenu}
            isTOCOpen={isTOCOpen}
            readOnly={!canEdit}
            setupModifiers={true}
            onChange={onChange}
            onChangeSection={onChangeSection}
            onChangeElement={onChangeElement}
            onChangeGroupElement={onChangeGroupElement}
            onChangeAvatar={onChangeAvatar}
            onChangeCollapse={onToggleClick}
            onChangeTOCOpen={onChangeTOCOpen}
            onMenuClick={onSettingsClick}
          />
        )}
        {previewSheet && previewSheet.sheet.data && (
          <Sheet
            className={clsx(styles.sheet, !isShowingSheet && styles.hideSheet, styles.tableSheet)}
            theme={theme}
            colors={colors}
            roomGuid={room.guid}
            model={previewSheet.sheet}
            tocMeta={
              templateForPreviewSheet && {
                name: templateForPreviewSheet.name,
                username: templateForPreviewSheet.ownerName,
              }
            }
            userName={sheetDisplayName}
            isPreview
            isCollapsed={!isShowingSheet}
            isTOCOpen={isTOCOpen}
            readOnly
            onAddSheet={noop}
            onChange={noop}
            onChangeSection={noop}
            onChangeElement={noop}
            onChangeGroupElement={noop}
            onChangeAvatar={noop}
            onChangeCollapse={onToggleClick}
            onChangeTOCOpen={onChangeTOCOpen}
            onMenuClick={noop}
            onSheetClick={noop}
          />
        )}
        {isViewingTemplates && <TableSheetCreator className={clsx(!isShowingSheet && styles.hide)} room={room} />}
      </div>

      {showRemoveSheetModal && renderRemoveSheetModal()}
      {isShowingInvite && <InviteModal onDismiss={() => dispatch(TableAction.showInvite(false))} />}
    </div>
  );
}

export default TableSheet;
