// @flow
import styles from './CollapsedTableOfContents.module.css';

import type { Node } from 'react';
import React, { useState } from 'react';

import { linearGradient } from 'utilities/color';
import posthog from 'posthog-js';

type Props = {
  className?: ?string,
  colors: string[],
  isActive: boolean,
  onClick: (string) => void,
  sections: any[],
};

function CollapsedTableOfContents(props: Props): Node {
  const { className, colors, isActive, onClick, sections } = props;

  const [hoverIndex, setHoverIndex] = useState(-2);

  const classNames = [styles.container];
  if (isActive) classNames.push(styles.isActive);
  if (className) classNames.push(className);

  return (
    <div className={classNames.join(' ')} style={{ '--background-hover': linearGradient(colors, '175deg') }}>
      <nav className={styles.nav}>
        {sections.map(({ id, title }, index) => {
          const isActive = hoverIndex > -2 && (index === hoverIndex - 1 || index === hoverIndex + 1);
          return (
            <button
              key={id}
              className={`button-reset ${styles.button} ${isActive ? styles.isActive : ''}`}
              onClick={() => {
                posthog.capture('collapsed toc - clicked', {
                  'section id': id
                });
                onClick(id);
              }}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(-2)}
            >
              <span className={styles.buttonContent}>
                <span className={styles.buttonSize}>{title}</span>
                <span className={styles.buttonBackground} />
                <span className={styles.buttonLabel}>{title}</span>
              </span>
            </button>
          );
        })}
      </nav>
    </div>
  );
}

CollapsedTableOfContents.defaultProps = {
  isActive: false,
};

export default CollapsedTableOfContents;
