import _ from 'lodash';
import { batch } from 'react-redux';
import { SubmissionError } from 'redux-form';

import { createAction, handleError } from './utilities';
import history from 'browserHistory';
import ProductSelector from 'store/selectors/ProductSelector';

import OrganizationAction from './OrganizationAction';
import ProductAction from './ProductAction';
import UserAction from './UserAction';
import role from 'apis/role';
import posthog from 'posthog-js';

export function processUserResponse({ data, included }) {
  const { id, attributes, relationships } = data;
  const organizationIds = relationships.organizations.data.map(({ id }) => id);
  const unlockedGameIds = relationships.unlockedGames.data.map(({ id }) => id);
  const unlockedProductIds = relationships.unlockedProducts.data.map(({ id }) => id);
  const unlockedFreeProductIds = relationships.unlockedFreeProducts.data.map(({ id }) => id);

  const user = { id, ...attributes, organizationIds, unlockedGameIds, unlockedProductIds, unlockedFreeProductIds };

  const organizations = [];
  const rooms = [];

  if (included) {
    for (const include of included) {
      const { id, type, attributes } = include;
      const obj = { id, ...attributes };
      if (type === 'organization') organizations.push(obj);
      if (type === 'room') rooms.push(obj);
    }
  }

  return { user, organizations, rooms };
}

// set to true for new users
const defaultFteStates = {
  showMultiStep: true,
  step: 0,
  genre: null,
  showRoomCreate: true,
  gameQuery: null,
};

export default class SessionAction {
  static CLEAR_INVITE = 'SessionAction.CLEAR_INVITE';
  static CLEAR_REQUESTED_PASSWORD_RESET = 'SessionAction.CLEAR_REQUESTED_PASSWORD_RESET';
  static FETCH_CURRENT_USER = 'SessionAction.FETCH_CURRENT_USER';
  static FETCH_INVITE = 'SessionAction.FETCH_INVITE';
  static PASSWORD_RESET = 'SessionAction.PASSWORD_RESET';
  static REDIRECT_TO = 'SessionAction.REDIRECT_TO';
  static REQUEST_PASSWORD_RESET = 'SessionAction.REQUEST_PASSWORD_RESET';
  static SET_SOCKET_ID = 'SessionAction.SET_SOCKET_ID';
  static SIGN_IN = 'SessionAction.SIGN_IN';
  static SIGN_OUT = 'SessionAction.SIGN_OUT';
  static SIGN_UP = 'SessionAction.SIGN_UP';
  static SIGN_UP_GUEST = 'SessionAction.SIGN_UP_GUEST';
  static UPDATE_FTE_STEP = 'SessionAction.UPDATE_FTE_STEP';
  static UPDATE_SHOW_MULTI_STEP = 'SessionAction.UPDATE_SHOW_MULTI_STEP';
  static UPDATE_FTE_GENRE = 'SessionAction.UPDATE_FTE_GENRE';
  static UPDATE_SHOW_ROOM_CREATE = 'SessionAction.UPDATE_SHOW_ROOM_CREATE';
  static UPDATE_FTE_GAME_QUERY = 'SessionAction.UPDATE_FTE_GAME_QUERY';

  static clearInvite = () => {
    return createAction(SessionAction.CLEAR_INVITE);
  };

  static clearRequestedPasswordReset = () => {
    return createAction(SessionAction.CLEAR_REQUESTED_PASSWORD_RESET);
  };

  static fetchCurrentUser = (callback) => async (dispatch) => {
    return role.get('/users/me').then(
      (response) => {
        const { attributes } = response.data;
        const { user, organizations } = processUserResponse(response.data);
        const isKickstarterBacker = !_.isEmpty(attributes?.kickstarterRewards?.options) || !_.isEmpty(attributes?.kickstarterRewards?.extras);
        if (!posthog._isIdentified()) {
          posthog.identify(
            user.id,
            { email: user.email, name: user.username, userId: user.id, isPatron: user.isPatron, kickstarterBacker: isKickstarterBacker },
          );          
        }
    
        batch(() => {
          dispatch(createAction(SessionAction.FETCH_CURRENT_USER, user));
          dispatch(createAction(OrganizationAction.FETCH_ALL, organizations));
        });

        callback?.(user);
      },
      ({ response }) => handleError(dispatch, SessionAction.FETCH_CURRENT_USER, response)
    );
  };

  static fetchInvite = (code) => async (dispatch) => {
    return role.get(`/waitlist_invites/${code}`).then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(SessionAction.FETCH_INVITE, obj));
      },
      ({ response }) => handleError(dispatch, SessionAction.FETCH_INVITE, response)
    );
  };

  static redirectTo = (path) => {
    return createAction(SessionAction.REDIRECT_TO, path);
  };

  static resetPassword = (formValues) => async (dispatch) => {
    return role.put('/settings/password', formValues).then(
      (response) => {
        dispatch(createAction(SessionAction.PASSWORD_RESET));
        history.push('/login');
      },
      ({ response }) => handleError(dispatch, SessionAction.PASSWORD_RESET, response)
    );
  };

  static requestPasswordReset = (formValues) => async (dispatch) => {
    return role.post('/settings/password', formValues).then(
      (response) => dispatch(createAction(SessionAction.REQUEST_PASSWORD_RESET)),
      (error) => dispatch(createAction(SessionAction.REQUEST_PASSWORD_RESET))
    );
  };

  static setSocketId = (id) => {
    return createAction(SessionAction.SET_SOCKET_ID, id);
  };

  static updateFteStep = (step) => {
    return createAction(SessionAction.UPDATE_FTE_STEP, step);
  };

  static updateShowMultiStepFte = (showMultiStep) => {
    return createAction(SessionAction.UPDATE_SHOW_MULTI_STEP, showMultiStep);
  };

  static updateFteGenre = (genre) => {
    return createAction(SessionAction.UPDATE_FTE_GENRE, genre);
  };

  static updateShowRoomCreateFte = (showRoomCreate) => {
    return createAction(SessionAction.UPDATE_SHOW_ROOM_CREATE, showRoomCreate);
  };

  static updateFteGameQuery = (gameQuery) => {
    return createAction(SessionAction.UPDATE_FTE_GAME_QUERY, gameQuery);
  };

  static signIn = (formValues, redirectTo) => async (dispatch, getState) => {
    return role.post('/login', formValues).then(
      (response) => {
        const token = response.headers.authorization.split(' ')[1];
        dispatch(createAction(SessionAction.SIGN_IN, { token }));

        if (!!redirectTo && redirectTo.includes('from_purchase/')) {
          const product = ProductSelector.get(getState(), redirectTo.split('/')[1]);
          if (product) {
            if (product.price === 0) {
              dispatch(
                ProductAction.redeem(product.guid, () => {
                  history.push(`/products/${product.guid}`);
                })
              );
            } else {
              ProductAction.checkout(product.stripeProductId).then((data) => {
                window.location = data.url;
              });
            }
          } else {
            dispatch(SessionAction.fetchCurrentUser((user) => history.push(redirectTo || '/dashboard')));
          }
        } else {
          dispatch(SessionAction.fetchCurrentUser((user) => history.push(redirectTo || '/dashboard')));
        }
      },
      ({ response }) => handleError(dispatch, SessionAction.SIGN_IN, response)
    );
  };

  static signOut = () => async (dispatch) => {
    return role.delete('/logout').then((response) => {
      posthog.reset();
      dispatch(createAction(SessionAction.SIGN_OUT));
      history.push('/');
    });
  };

  static signUp = (formValues, redirectTo, callback) => async (dispatch, getState) => {
    const formData = new FormData();
    if (formValues.username) formData.append('user[username]', formValues.username);
    if (formValues.email) formData.append('user[email]', formValues.email);
    if (formValues.password) formData.append('user[password]', formValues.password);
    if (formValues.avatar && formValues.avatar[0]) formData.append('user[avatar]', formValues.avatar[0]);
    if (formValues.isGuest) formData.append('user[is_guest]', formValues.isGuest);
    if (formValues.code) formData.append('code', formValues.code);
    if (!redirectTo) formData.append('create_room', true);

    return role.post('/signup', formData).then(
      (response) => {
        const token = response.headers.authorization.split(' ')[1];
        const { user: currentUser, rooms } = processUserResponse(response.data, response.included);
        const payload = { user: { metadata: { ...currentUser.metadata, fte: defaultFteStates } } };
        batch(() => {
          dispatch(createAction(SessionAction.SIGN_UP, { token }));
          // set fte state here so only new sign ups see them
          dispatch(UserAction.updateJson(currentUser.id, payload));
        });

        if (!!redirectTo && redirectTo.includes('from_purchase/')) {
          const product = ProductSelector.get(getState(), redirectTo.split('/')[1]);
          if (product) {
            if (product.price === 0) {
              dispatch(
                ProductAction.redeem(product.guid, () => {
                  history.push(`/products/${product.guid}`);
                })
              );
            } else {
              ProductAction.checkout(product.stripeProductId).then((data) => {
                window.location = data.url;
              });
            }
          } else {
            dispatch(
              SessionAction.fetchCurrentUser((user) => {
                if (callback) callback(user);
                history.push(redirectTo || '/dashboard');
              })
            );
          }
        } else if (!!redirectTo) {
          dispatch(createAction(SessionAction.FETCH_CURRENT_USER, currentUser));
          if (callback) callback(currentUser);
          history.push(redirectTo);
        } else {
          dispatch(createAction(SessionAction.FETCH_CURRENT_USER, currentUser));
          history.push(rooms.length > 0 ? `rooms/${rooms[0].guid}` : '/dashboard');
        }
      },
      ({ response }) => {
        handleError(dispatch, SessionAction.SIGN_UP, response);

        const errors = {};
        for (const [key, value] of Object.entries(response.data.errors)) {
          const val = Array.isArray(value) ? value[0] : value;
          if (key === 'email') {
            if (val.includes('taken')) errors.email = 'Email already in use';
            else errors.email = 'Invalid email';
          } else if (key === 'username') {
            if (val.includes('taken')) errors.username = 'Username already in use';
            else errors.username = 'Invalid username';
          } else if (key === 'password') {
            errors.password = 'Invalid password';
          } else if (key === 'code') {
            errors.code = 'Invalid code';
          }
        }
        throw new SubmissionError(errors);
      }
    );
  };

  static signUpGuest = (redirectTo) => async (dispatch) => {
    return role.post('/signup/guest').then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(SessionAction.SIGN_UP_GUEST, obj));
        history.push(redirectTo || '/dashboard');
      },
      ({ response }) => handleError(dispatch, SessionAction.SIGN_UP_GUEST, response)
    );
  };
}
