// @flow
import styles from './DashboardLayout.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { SessionSelector } from 'store/selectors';

import MainLayout from './MainLayout';
import SPGBanner from './SPGBanner';
import Button from 'components/buttons/Button';
import Modal from 'components/Modal';
import AssetsSample from 'images/assets_sample.png';
import GameRoomSample from 'images/game_room_sample.png';
import { ReactComponent as InfoIcon } from 'images/icons/InfoIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import SheetBuilderSample from 'images/sheet_builder_sample.png';

type Props = {
  children?: ?Node,
  sidebarChildren?: ?Node,
};

function DashboardLayout({ children, sidebarChildren }: Props): Node {
  const [currentTab, setCurrentTab] = useState('');
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const isMaterials = history.location.pathname === '/materials';
  const isAuthed = useSelector(SessionSelector.isAuthed);
  const currentUser = useSelector(SessionSelector.currentUser);
  // const showSPGBanner = useSelector(SessionSelector.showSPGBanner);
  const hasPurchases = useMemo(() => currentUser?.unlockedProductIds.length > 0, [currentUser]);

  useEffect(() => {
    setCurrentTab(history.location.pathname.split('/')[1]);
  }, [history.location.pathname]);

  const onCreateClick = (wobject) => {
    switch (wobject) {
      case 'sheet':
        history.push('/sheet-templates/new');
        break;
      case 'asset':
        history.push('/assets/new');
        break;
      case 'room':
      default:
        history.push('/rooms/new');
        break;
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (hasPurchases && isMaterials) {
      history.replace({ pathname: '/purchases' });
    }
  }, [hasPurchases, history, isMaterials]);

  return (
    <MainLayout
      navMenu={
        <nav className={styles.nav}>
          <Link to="/games" className={clsx(currentTab === 'games' && styles.isActive)}>
            Store
          </Link>
          {isAuthed && (
            <>
              <Link to="/rooms" className={clsx(currentTab === 'rooms' && styles.isActive)}>
                Rooms
              </Link>
              <Link
                to="/materials"
                className={clsx(
                  ['sheets', 'sheet-templates', 'assets', 'purchases'].includes(currentTab) && styles.isActive,
                  styles.materialsParentLink
                )}
              >
                Play Materials
              </Link>
              <Link to="/sheets" className={clsx('sheets' === currentTab && styles.isActive, styles.materialsSubLink)}>
                Sheets
              </Link>
              <Link
                to="/sheet-templates"
                className={clsx('sheet-templates' === currentTab && styles.isActive, styles.materialsSubLink)}
              >
                Templates
              </Link>
              <Link
                to="/purchases"
                className={clsx('purchases' === currentTab && styles.isActive, styles.materialsSubLink)}
              >
                Purchased Assets
              </Link>
              <Link to="/assets" className={clsx('assets' === currentTab && styles.isActive, styles.materialsSubLink)}>
                Your Assets
              </Link>
            </>
          )}
        </nav>
      }
      navChildren={
        isAuthed && (
          <nav className={styles.navContainer}>
            <Button
              variant="primary"
              className={styles.tableButton}
              onClick={() => setShowModal(true)}
              icon={<PlusIcon />}
            >
              Create
            </Button>
          </nav>
        )
      }
    >
      <div className={clsx(styles.container, sidebarChildren && styles.showSidebar)}>
        {sidebarChildren && (
          <aside className={styles.sidebar}>
            <header className={styles.sidebarHeader}>
              <h3 className={styles.sidebarHeading}>Your Play Materials</h3>
            </header>
            {sidebarChildren && <div className={styles.sidebarContent}>{sidebarChildren}</div>}
          </aside>
        )}
        {/* {showSPGBanner && (
          <div className={styles.banner}>
            <SPGBanner />
          </div>
        )} */}
        <div className={styles.content}>{children}</div>
      </div>
      {showModal && (
        <Modal maxWidth="1040px" width="auto" onDismiss={() => setShowModal(false)} className={styles.createModal}>
          <div className={styles.header}>
            <h3 className={styles.title}>Creating on Role</h3>
            <div className={styles.subtitle}>
              There are many paths to create and customize your play experience on Role.
              <br />
              Create game sheets using our Sheet Builder, set up Game Rooms for your friends to party up and play, or
              add Game Assets (Maps, Tokens, Rulebooks, etc.) to customize your play experience.
            </div>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.createOptions}>
              <div className={styles.option}>
                <img src={SheetBuilderSample} alt="Sheet Builder Sample" />
                <div className={styles.createText}>
                  <h3>Sheet Builder</h3>
                  <p>Use our drag and drop Elements Library to create custom sheets and build your own games.</p>
                </div>
                <Button
                  variant="primary"
                  onClick={() => onCreateClick('sheet')}
                  className={styles.optionButton}
                  icon={<PlusIcon />}
                >
                  Sheet
                </Button>
              </div>
              <div className={styles.option}>
                <img src={GameRoomSample} alt="Game Room Sample" style={{ paddingBottom: '24px' }} />
                <div className={styles.createText}>
                  <h3>Create a Game Room</h3>
                  <p>Start a new Game Room where you can customize and add sheets and invite your friends to play.</p>
                </div>
                <Button
                  variant="primary"
                  onClick={() => onCreateClick('room')}
                  className={styles.optionButton}
                  icon={<PlusIcon />}
                >
                  Room
                </Button>
              </div>
              <div className={styles.option}>
                <img src={AssetsSample} alt="Assets Sample" />
                <div className={styles.createText}>
                  <h3>Add Game Assets</h3>
                  <p>Upload your own Game Assets to make your play experience as unique as you are.</p>
                </div>
                <Button
                  variant="primary"
                  onClick={() => onCreateClick('asset')}
                  className={styles.optionButton}
                  icon={<PlusIcon />}
                >
                  Asset
                </Button>
              </div>
            </div>
            <div className={styles.createFooter}>
              <InfoIcon width={36} style={{ flex: 'none', fill: 'var(--color-dashboard-light)' }} />
              <div>
                <h4>Want to learn more about what you can do on Role?</h4>
                <p>
                  <a
                    href="https://playrole.notion.site/Role-Guide-FAQ-658f294715ed4fdc9380c9e67163eaf7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View our FAQ
                  </a>{' '}
                  for more info on how to use Role's creation features.
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </MainLayout>
  );
}

export default DashboardLayout;
