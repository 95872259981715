import './styles/global.css';
import './styles/rui.css';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import posthog from 'posthog-js';

import store from './store';
import { VERSION } from 'models/Constants';

import App from './App';
import SimpleReactLightbox from 'simple-react-lightbox';

const posthogKey = process.env.REACT_APP_POSTHOG_API_KEY;
const posthogApiUrl = process.env.REACT_APP_POSTHOG_API_URL;
if (posthogKey) {
  posthog.init(posthogKey, {
    api_host: posthogApiUrl,
    persistence: 'memory',
    disable_persistence: true
   })
}

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  appVersion: VERSION,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
