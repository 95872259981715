import styles from './CardDrawer.module.css';

import clsx from 'clsx';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CardView, ShuffleMode } from 'models/Card';
import { CardAction, RoomAction, TableAction } from 'store/actions';
import { CardSelector, SessionSelector, TableSelector } from 'store/selectors';
import posthog from 'posthog-js';

import Card from './Card';
import CardOutline from './CardOutline';
import CardPile from './CardPile';
import Button from 'components/buttons/Button';
import Modal from 'components/Modal';
import ToggleSwitch from 'components/ToggleSwitch';

class CardDrawer extends Component {
  heartbeatTimer = null;
  state = {
    checkboxes: {
      [ShuffleMode.DECK]: true,
      [ShuffleMode.DISCARD]: true,
      [ShuffleMode.TABLE]: true,
      [ShuffleMode.PLAYER]: true,
    },
  };

  logHeartbeat = () => {
    posthog.capture('table - cards open heartbeat', {
      'current deck': this.props.currentDeck || 'standard52',
    });
  };

  componentDidMount() {
    const { currentDeck, selectDeck } = this.props;
    // TODO: Make this a smarter selection, such as last deck used
    if (!currentDeck) selectDeck('standard52');

    this.logHeartbeat();
    this.heartbeatTimer = setInterval(this.logHeartbeat, 300000);
  }

  componentWillUnmount() {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  }

  componentDidUpdate(prevProps) {
    const { currentDeck, deckCards, isShuffling, room } = this.props;
    const { updateDeckCards } = this.props;
    if (
      prevProps.isShuffling &&
      !isShuffling &&
      prevProps.currentDeck.id === currentDeck.id &&
      prevProps.deckCards !== deckCards
    ) {
      updateDeckCards(room.guid, currentDeck);
    }
  }

  onCancelShuffleClick = () => {
    this.props.cancelShuffle();
  };

  onConfirmShuffleClick = () => {
    this.props.confirmShuffle(this.state.checkboxes);
  };

  onSelectShuffleClick = () => {
    this.props.selectShuffle();
  };

  onDeckChange = (event) => {
    this.props.selectDeck(event.target.value);
  };

  onShuffleCheckboxChange = (event) => {
    const { value } = event.target;

    this.setState((prevState) => {
      const { checkboxes } = prevState;
      const deckState = value === ShuffleMode.DECK ? !checkboxes[ShuffleMode.DECK] : checkboxes[ShuffleMode.DECK];
      const discardState =
        value === ShuffleMode.DISCARD ? !checkboxes[ShuffleMode.DISCARD] : checkboxes[ShuffleMode.DISCARD];
      const tableState = value === ShuffleMode.TABLE ? !checkboxes[ShuffleMode.TABLE] : checkboxes[ShuffleMode.TABLE];
      const playerState =
        value === ShuffleMode.PLAYER ? !checkboxes[ShuffleMode.PLAYER] : checkboxes[ShuffleMode.PLAYER];
      const forceDeselect = !deckState && !discardState;

      return {
        ...prevState,
        checkboxes: {
          [ShuffleMode.DECK]: deckState,
          [ShuffleMode.DISCARD]: discardState,
          [ShuffleMode.TABLE]: forceDeselect ? false : tableState,
          [ShuffleMode.PLAYER]: forceDeselect ? false : playerState,
        },
      };
    });
  };

  onViewChange = (event) => {
    this.props.selectView(event.target.value);
  };

  onOpenDrawChange = (checked) => {
    const { room, updateRoom } = this.props;
    const settings = { ...room.settings, openDraw: checked };
    updateRoom(room.guid, { room: { settings } });
  };

  onCloseClick = () => {
    this.props.close();
  };

  renderHeader = () => {
    const { isGm, isOpenDraw, isShuffling, deckTemplates, currentDeck, currentView, colors } = this.props;

    if (isShuffling) {
      return <h3 className={`heading2 ${styles.header} ${styles.isShuffling}`}>Shuffle the cards?</h3>;
    }

    return (
      <div className={styles.header}>
        <div className={styles.selectContainer}>
          <label className={`heading2 ${styles.selectLabel}`}>Choose Deck</label>
          <select onChange={this.onDeckChange} value={currentDeck} className={styles.selectInput}>
            {deckTemplates.map((d) => {
              return (
                <option key={d.id} value={d.id}>
                  {d.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className={styles.selectContainer}>
          <label className={`heading2 ${styles.selectLabel}`}>View</label>
          <select onChange={this.onViewChange} value={currentView} className={styles.selectInput}>
            <option value={CardView.PLAYER}>My Hand</option>
            <option value={CardView.TABLE}>Table Cards</option>
          </select>
        </div>

        {isGm && (
          <ToggleSwitch label="Open Draw" colors={colors} checked={isOpenDraw} onChange={this.onOpenDrawChange} />
        )}
      </div>
    );
  };

  renderContent = () => {
    const {
      room,
      isGm,
      isOpenDraw,
      isShuffling,
      currentDeck,
      currentView,
      currentUser: { id: userId },
      undrawnCards,
      discardedCards,
    } = this.props;

    if (isShuffling) return this.renderShuffling();

    return (
      <>
        <div className={styles.piles}>
          <CardPile
            room={room}
            currentDeck={currentDeck}
            currentView={currentView}
            cards={undrawnCards}
            userId={userId}
            isGm={isGm}
            isOpenDraw={isOpenDraw}
          />
          <CardPile
            room={room}
            currentDeck={currentDeck}
            currentView={currentView}
            cards={discardedCards}
            userId={userId}
            isDiscard
            isGm={isGm}
            isOpenDraw={isOpenDraw}
          />
        </div>

        <div className={styles.cards}>
          {this.renderCards()}
          <CardOutline
            room={room}
            currentDeck={currentDeck}
            currentView={currentView}
            cards={undrawnCards}
            userId={userId}
            isGm={isGm}
            isOpenDraw={isOpenDraw}
          />
        </div>
      </>
    );
  };

  renderCards = () => {
    const { room, currentDeck, currentView, drawnCards, isGm, isOpenDraw } = this.props;
    const canDiscard = currentView === CardView.PLAYER || (currentView === CardView.TABLE && (isGm || isOpenDraw));

    return drawnCards.map((card) => {
      return <Card key={card.id} room={room} deckId={currentDeck} card={card} canDiscard={canDiscard} />;
    });
  };

  renderShuffling = () => {
    const { checkboxes } = this.state;

    return (
      <div className={styles.shuffleContent}>
        <h4 className={styles.shuffleHeading}>Select which cards to shuffle</h4>

        {Object.entries(checkboxes).map((checkbox) => {
          return this.renderCheckbox(checkbox);
        })}

        {this.renderShuffleDescription()}
      </div>
    );
  };

  renderCheckbox = (checkbox) => {
    const id = checkbox[0];
    const checked = checkbox[1];

    return (
      <label key={id} className={styles.shuffleLabel}>
        <input onChange={this.onShuffleCheckboxChange} type="checkbox" name={id} value={id} checked={checked} />
        <span className="heading3">{id}</span>
      </label>
    );
  };

  renderShuffleDescription = () => {
    const { checkboxes } = this.state;
    const deck = checkboxes[ShuffleMode.DECK];
    const discard = checkboxes[ShuffleMode.DISCARD];
    const table = checkboxes[ShuffleMode.TABLE];
    const player = checkboxes[ShuffleMode.PLAYER];
    let description = 'Shuffle nothing. Select from the options above or click cancel to return.';

    if (deck && !discard) {
      if (table && !player) {
        description = 'Merge table cards back into the deck.';
      } else if (!table && player) {
        description = 'Merge all player cards back into the deck.';
      } else if (table && player) {
        description = 'Merge table cards and all player cards back into the deck.';
      } else {
        description = 'Shuffle the deck only.';
      }
    } else if (!deck && discard) {
      if (table && !player) {
        description = 'Merge table cards into the discard pile.';
      } else if (!table && player) {
        description = 'Merge all player cards into the discard pile.';
      } else if (table && player) {
        description = 'Merge table cards and all player cards into the discard pile.';
      } else {
        description = 'Shuffle the discard pile only.';
      }
    } else if (deck && discard) {
      if (table && !player) {
        description = 'Merge table cards and discard pile back into the deck.';
      } else if (!table && player) {
        description = 'Merge all player cards and discard pile back into the deck.';
      } else if (table && player) {
        description = 'Merge all cards back into the deck.';
      } else {
        description = 'Merge discard pile back into the deck.';
      }
    }

    return (
      <p className={styles.shuffleDescription}>
        <strong>This will: </strong>
        {description}
      </p>
    );
  };

  renderActions() {
    const { isGm, isOpenDraw, isShuffling, colors } = this.props;

    if (!isGm && !isOpenDraw) return null;

    if (isShuffling) {
      return (
        <>
          <Button
            variant="cancel"
            color="var(--color-white)"
            hoverColor="var(--color-dark-text)"
            onClick={this.onCancelShuffleClick}
          >
            Cancel
          </Button>

          <Button onClick={this.onConfirmShuffleClick} primaryBackground={colors[0]} secondaryBackground={colors[1]}>
            Shuffle
          </Button>
        </>
      );
    }

    return (
      <Button onClick={this.onSelectShuffleClick} primaryBackground={colors[0]} secondaryBackground={colors[1]}>
        Shuffle
      </Button>
    );
  }

  render() {
    const { currentDeck, className } = this.props;
    return (
      <Modal maxWidth="800px" width="100%" onDismiss={this.onCloseClick} className={clsx(className)}>
        <header className={styles.modalHeader}>
          <h3 className={styles.modalTitle}>Cards</h3>
        </header>
        <div className={styles.modalContent}>
          {currentDeck && this.renderHeader()}
          {currentDeck && this.renderContent()}
          <div className={styles.actions}>{currentDeck && this.renderActions()}</div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { room } = props;
  const currentUser = SessionSelector.currentUser(state);
  // TODO: Update this if we ever allow a room to have a different GM than the owner
  const isGm = room.userId === currentUser.id;

  return {
    currentDeck: CardSelector.getCurrentDeck(state),
    currentUser,
    currentView: CardSelector.getCurrentView(state),
    decks: CardSelector.getDecks(state),
    deckTemplates: CardSelector.getTemplates(state),
    deckCards: CardSelector.getDeckCards(state),
    discardedCards: CardSelector.getDiscardedCards(state),
    drawnCards: CardSelector.getDrawnCards(state),
    isGm,
    isOpenDraw: TableSelector.isOpenDraw(state, room.guid),
    isShuffling: CardSelector.isShuffling(state),
    undrawnCards: CardSelector.getCards(state),
  };
};

const mapDispatchToProps = {
  cancelShuffle: CardAction.cancelShuffle,
  close: TableAction.hideCardDrawer,
  confirmShuffle: CardAction.confirmShuffle,
  selectDeck: CardAction.selectDeck,
  selectShuffle: CardAction.selectShuffle,
  selectView: CardAction.selectView,
  updateDeckCards: RoomAction.updateDeckCards,
  updateRoom: RoomAction.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDrawer);
