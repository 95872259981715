// @flow
import styles from './ProductCard.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import COLOR, { DASHBOARD_COLOR } from 'models/Color';
import { GameSelector, OrganizationSelector, RoomSelector } from 'store/selectors';
import posthog from 'posthog-js';
import { stylizeTheme } from 'utilities/color';
import { formatCents } from 'utilities/numbers';

import IconTitleButton from 'components/buttons/IconTitleButton';
import Tag from 'components/Tag';
import { ReactComponent as D20Icon } from 'images/dice/D20.svg';
import { ReactComponent as PriceTagIcon } from 'images/icons/PriceTagIcon.svg';

type Props = {
  roomGuid?: string,
  product: any,
  className?: string,
  isPlayPanel?: boolean,
  isSmall?: boolean,
  isDetailView?: boolean,
  source?: string,
};

const theme = {
  base: COLOR.WHITE,
  button: COLOR.PURPLE,
  text: DASHBOARD_COLOR.DARK,
  accent: DASHBOARD_COLOR.WHITE,
};

function ProductCard({ className, isSmall, isPlayPanel, product, roomGuid, isDetailView, source }: Props): Node {
  const { id, title, coverArtUrl, price, slug, ownerId, gameId, stripeProductId, isUnlocked, guid, isExclusive } =
    product;

  const organization = useSelector((state) => OrganizationSelector.getById(state, ownerId));
  const game = useSelector((state) => GameSelector.get(state, gameId));
  const latestGameRoom = useSelector((state) => RoomSelector.getLatestRoomByGameId(state, game?.id));

  const isDefault = useMemo(() => {
    return [game?.defaultFreeProductGuid, game?.defaultPaidProductGuid].includes(guid);
  }, [game, guid]);

  const productTitle = isDefault ? game?.title : title;

  const productLink = useMemo(() => {
    if (roomGuid) {
      if (isDefault) {
        return `/games/${game?.slug}?room=${roomGuid}`;
      } else {
        return `/games/${game?.slug}/${slug}?room=${roomGuid}`;
      }
    } else {
      if (isDefault) {
        return `/games/${game?.slug}`;
      } else {
        return `/games/${game?.slug}/${slug}`;
      }
    }
  }, [game, roomGuid, isDefault, slug]);

  const style = theme && stylizeTheme(theme);

  const logBuyGameEvent = useCallback(() => {
    let eventSource = source || 'games page';
    if (roomGuid) eventSource = 'room';
    posthog.capture('product card - click buy', {
      'product id': id,
      'game id': gameId,
      'source': eventSource,
      'price': price,
      'game title': game?.title,
      'game complexity': game?.complexity,
      'game min players': game?.minPlayers,
      'game max players': game?.maxPlayers,
      'game session length': game?.sessionLength,
    });
  }, [
    source,
    roomGuid,
    id,
    gameId,
    price,
    game?.title,
    game?.complexity,
    game?.minPlayers,
    game?.maxPlayers,
    game?.sessionLength,
  ]);

  const logCreateNewGameRoomEvent = useCallback(() => {
    const eventSource = source || 'games page';
    posthog.capture('product card - click create room', {
      'product id': id,
      'game id': gameId,
      'game title': game?.title,
      'source': eventSource,
      'is unlocked': isUnlocked,
    });
  }, [source, id, gameId, game?.title, isUnlocked]);

  const isQuickstart = price === null;
  const isFree = price === 0;
  const isBuyable = stripeProductId && price > 0;

  const renderQuickstartButtons = useCallback(() => {
    if (isDetailView) {
      return (
        <IconTitleButton to={productLink} variant="flat">
          <span>
            <span>{isUnlocked ? 'Owned' : 'Quick-Start'}</span>
            <span>View Details</span>
          </span>
        </IconTitleButton>
      );
    } else {
      return (
        <>
          {latestGameRoom && (
            <IconTitleButton to={`/rooms/${latestGameRoom.guid}`}>
              <>
                <D20Icon />
                <span>
                  <span>Latest Room</span>
                  <span>Play Now</span>
                </span>
              </>
            </IconTitleButton>
          )}
          <IconTitleButton onClick={logCreateNewGameRoomEvent} to={`${productLink}/rooms/new`} variant="flat">
            <>
              <D20Icon />
              <span>
                <span>Quick-Start</span>
                <span>Start New</span>
              </span>
            </>
          </IconTitleButton>
        </>
      );
    }
  }, [isDetailView, productLink, isUnlocked, latestGameRoom, logCreateNewGameRoomEvent]);

  const renderClaimButtons = useCallback(() => {
    return (
      <>
        {!isUnlocked && (
          <IconTitleButton onClick={logBuyGameEvent} to={productLink} isTargetBlank={isPlayPanel} variant="green">
            <>
              <PriceTagIcon />
              <span>
                <span>Claim</span>
                <span>Free</span>
              </span>
            </>
          </IconTitleButton>
        )}

        {isUnlocked && latestGameRoom && !isPlayPanel && (
          <IconTitleButton to={`/rooms/${latestGameRoom.guid}`}>
            <>
              <D20Icon />
              <span>
                <span>Latest Room</span>
                <span>Play Now</span>
              </span>
            </>
          </IconTitleButton>
        )}

        {isUnlocked && !isPlayPanel && (
          <IconTitleButton onClick={logCreateNewGameRoomEvent} to={`${productLink}/rooms/new`} variant="red">
            <>
              <D20Icon />
              <span>
                <span>Owned</span>
                <span>Start New</span>
              </span>
            </>
          </IconTitleButton>
        )}
      </>
    );
  }, [isUnlocked, productLink, isPlayPanel, latestGameRoom, logBuyGameEvent, logCreateNewGameRoomEvent]);

  const renderBuyButtons = useCallback(() => {
    return (
      <>
        {!isUnlocked && (
          <>
            <IconTitleButton onClick={logBuyGameEvent} to={productLink} isTargetBlank={isPlayPanel} variant="green">
              <>
                <PriceTagIcon />
                <span>
                  <span>Buy</span>
                  <span>{formatCents(price)}</span>
                </span>
              </>
            </IconTitleButton>
            {!isPlayPanel && game && (
              <IconTitleButton onClick={logCreateNewGameRoomEvent} to={`/games/${game.slug}/preview`} variant="flat">
                <>
                  <D20Icon />
                  <span>
                    <span>Preview</span>
                    <span>Free</span>
                  </span>
                </>
              </IconTitleButton>
            )}
          </>
        )}

        {isUnlocked && latestGameRoom && !isPlayPanel && !isDetailView && (
          <IconTitleButton to={`/rooms/${latestGameRoom.guid}`}>
            <>
              <D20Icon />
              <span>
                <span>Latest Room</span>
                <span>Play Now</span>
              </span>
            </>
          </IconTitleButton>
        )}

        {isUnlocked && !isPlayPanel && !isDetailView && (
          <IconTitleButton onClick={logCreateNewGameRoomEvent} to={`${productLink}/rooms/new`} variant="red">
            <>
              <D20Icon />
              <span>
                <span>Owned</span>
                <span>Start New</span>
              </span>
            </>
          </IconTitleButton>
        )}

        {isUnlocked && isDetailView && (
          <IconTitleButton to={productLink} variant="flat">
            <span>
              <span>Owned</span>
              <span>View Details</span>
            </span>
          </IconTitleButton>
        )}
      </>
    );
  }, [
    isUnlocked,
    logBuyGameEvent,
    productLink,
    isPlayPanel,
    price,
    game,
    logCreateNewGameRoomEvent,
    latestGameRoom,
    isDetailView,
  ]);

  return (
    <div
      className={clsx(
        styles.container,
        className,
        isSmall && styles.isSmall,
        isPlayPanel && (isDefault ? styles.panelStore : styles.isSmall)
      )}
      style={style}
    >
      {isExclusive && <div className={styles.exclusive}>Exclusive</div>}
      <div className={styles.content}>
        <Link className={styles.image} to={productLink} target={isPlayPanel ? '_blank' : '_self'}>
          <img src={coverArtUrl || game?.coverArtUrl} alt={productTitle} />
        </Link>
        <div className={styles.body}>
          <Link to={productLink} target={isPlayPanel ? '_blank' : '_self'} className={styles.title}>
            {productTitle}
          </Link>
          {organization && (
            <Tag
              title={organization.title}
              to={`/publishers/${organization.slug}`}
              target={isPlayPanel ? '_blank' : '_self'}
              className={styles.organization}
            />
          )}
          <div className={styles.buttonActions}>
            {isQuickstart && !isPlayPanel && renderQuickstartButtons()}
            {isFree && renderClaimButtons()}
            {isBuyable && renderBuyButtons()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
