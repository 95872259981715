//@flow
import styles from './AssetTray.module.css';

import { capitalize } from 'lodash';
import { useCallback, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import useWindowSize from 'hooks/useWindowSize';
import Document from 'models/Document';
import { AssetAction, MediaAction, TableAction } from 'store/actions';
import { DocumentSelector, GameSelector, TableSelector } from 'store/selectors';
import { RoomTab } from 'types/room';
import posthog from 'posthog-js'; 

import AssetCard from 'components/cards/AssetCard';

export default function PreviewRoomDocument({ previewDocument, theme }) {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const document = useSelector((state) => DocumentSelector.get(state, previewDocument.id));
  const asset = useMemo(() => new Document(document), [document]);
  const game = useSelector((state) => previewRoom && GameSelector.getBySlug(state, previewRoom));

  const activeRoomDocument = useSelector(TableSelector.getActiveRoomDocument);

  const onAssetViewPrivately = useCallback(() => {
    const { guidId } = previewDocument;

    batch(() => {
      dispatch(TableAction.setPrivateRoomDocument(guidId));

      if (!activeRoomDocument || (activeRoomDocument && activeRoomDocument.id !== guidId)) {
        dispatch(TableAction.setActiveRoomDocument(guidId));
        dispatch(MediaAction.setCanvasScale(1));
      }

      dispatch(AssetAction.showMenu(null));
      if (size.isMd) dispatch(TableAction.setPlayPanelContent(null));
      else dispatch(TableAction.setCurrentTab(RoomTab.Party));
    });
    posthog.capture('preview asset - view privately', {
      'asset type': asset.type,
      'from game': asset.gameIds.length > 0,
      'from product': asset.productIds.length > 0,
      'game id': game?.id,
      'game title': game?.title,
    });
  }, [
    previewDocument,
    asset.type,
    asset.gameIds.length,
    asset.productIds.length,
    game?.id,
    game?.title,
    dispatch,
    activeRoomDocument,
    size.isMd,
  ]);

  const menuItems = useMemo(() => {
    const { fileUrl, externalUrl } = asset;

    const items = [];
    if (!asset.isPdf() && !asset.isToken() && !asset.isLink()) {
      items.push({ label: 'View Privately', onClick: onAssetViewPrivately });
    }

    items.push({
      label: 'View in New Window',
      url: externalUrl ?? fileUrl,
      urlCallback: () => {
        posthog.capture('preview asset - view in new window', {
          'asset type': asset.type,
          'from game': asset.gameIds.length > 0,
          'from product': asset.productIds.length > 0,
          'game id': game?.id,
          'game title': game?.title,
        });
      },
    });

    return items;
  }, [asset, onAssetViewPrivately, game]);

  const tags = useMemo(
    () => [
      {
        title: game.title,
        color: 'var(--color-accent-accent)',
      },
    ],
    [game.title]
  );

  return (
    <div className={`${styles.asset} ${styles[`is${capitalize(asset.type)}`]}`}>
      <AssetCard
        asset={asset}
        roomDocument={previewDocument}
        menuItems={menuItems}
        tags={tags}
        showActions
        isClickable
        className={styles.playPanelCard}
        theme={theme}
        variant="theme"
      />
    </div>
  );
}
