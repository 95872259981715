//@flow
import styles from './TableSheetCreator.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_COLORS } from 'models/Color';
import { TableAction } from 'store/actions';
import {
  GameSelector,
  ProductSelector,
  RoomDocumentSelector,
  SheetSelector,
  SheetTemplateSelector,
  TableSelector,
} from 'store/selectors';
import posthog from 'posthog-js';

import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import LoadingSpinner from 'components/LoadingSpinner';
type Props = {
  isLoading: Boolean,
  className?: ?string,
};

function PreviewSheetCreator({ isLoading, className }: Props) {
  const dispatch = useDispatch();

  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const previewColors = useSelector((state) =>
    previewRoom ? RoomDocumentSelector.getPreviewColorsBySlug(state, previewRoom) : DEFAULT_COLORS
  );
  const game = useSelector((state) => GameSelector.getBySlug(state, previewRoom));
  const freeProduct = useSelector((state) => {
    if (game) {
      return ProductSelector.get(state, game.defaultFreeProductGuid);
    } else {
      return null;
    }
  });
  const gameMaterialSheets = useSelector((state) => (game ? SheetSelector.getGameSheetsByGameId(game.id)(state) : []));
  const gameMaterialSheetTemplates = useSelector((state) =>
    game ? SheetTemplateSelector.getGameSheetTemplatesByGameId(game.id)(state) : []
  );

  const roomHasPreGenSheetsAndTemplates = !_.isEmpty(gameMaterialSheets) && !_.isEmpty(gameMaterialSheetTemplates);

  const renderPreGenSheetTemplates = useCallback(() => {
    if (_.isEmpty(gameMaterialSheetTemplates)) return null;

    const onLoadPreviewTemplate = (preGenTemplateId: string) => {
      const preGenTemplate = gameMaterialSheetTemplates?.find((s) => s.id === preGenTemplateId);
      if (!preGenTemplate) return;
      posthog.capture('preview room - view pre-gen template', {
        'game id': game.id,
        'game title': game.title,
        'template id': preGenTemplate.id,
        'template name': preGenTemplate.name,
      });

      let payload = {
        sheet: preGenTemplate,
        room_guid: null,
        previewRoom: previewRoom, // for logging
        isTemplate: true,
        is_playkit_template: false,
        is_game_template: true,
        game_id: game.id,
        game_title: game?.title,
        product_ids: [freeProduct.id],
        product: { id: freeProduct.id, sheet_id: preGenTemplate.id, game_id: game.id, game_title: game?.title },
      };
      dispatch(TableAction.setPreviewSheet(payload));
    };

    return (
      <>
        <h3 className={styles.heading}>Blank Sheets & Extras</h3>
        <div className={styles.subtitle}>Empty sheets to fill in and other game resources</div>
        <div className={styles.premadeSheets}>
          {gameMaterialSheetTemplates.map((s) => (
            <div key={s.id} className={styles.premadeSheet}>
              <Avatar
                avatarUrl={s.avatarUrl ?? null}
                avatarAlt={s.name}
                size={60}
                borderSize={2}
                shape="squircle"
                borderColors={previewColors}
                isDdbSheet={!!s.ddbId}
                showSheetDefault
                className={styles.avatar}
              />
              <span className={styles.premadeName}>{s.name}</span>
              <Button
                className={styles.playButton}
                onClick={() => onLoadPreviewTemplate(s.id)}
                primaryBackground="rgb(var(--color-theme-accent))"
              >
                View
              </Button>
            </div>
          ))}
        </div>
      </>
    );
  }, [dispatch, freeProduct?.id, game?.id, game?.title, gameMaterialSheetTemplates, previewRoom, previewColors]);

  const renderPreGenSheets = useCallback(() => {
    if (_.isEmpty(gameMaterialSheets)) return null;

    const onLoadPreviewSheet = (preGenSheetId: string) => {
      const sheet = gameMaterialSheets?.find((s) => s.id === preGenSheetId);
      if (!sheet) return;
      posthog.capture('preview room - view pre-gen sheet', {
        'game id': game.id,
        'game title': game.title,
        'sheet id': sheet.id,
        'sheet name': sheet.name,
      });
      let payload = {
        sheet,
        room_guid: null, // check if backend consumes this
        previewRoom: previewRoom,
        product: { id: freeProduct.id, sheet_id: sheet.id, game_id: game.id, game_title: game.title },
      };

      dispatch(TableAction.setPreviewSheet(payload));
    };

    return (
      <>
        <h3 className={styles.heading}>Premade Sheets</h3>
        <div className={styles.subtitle}>Ready to jump in and start playing</div>
        <div className={styles.premadeSheets}>
          {gameMaterialSheets.map((s) => (
            <div key={s.id} className={styles.premadeSheet}>
              <Avatar
                avatarUrl={s.avatarUrl ?? null}
                avatarAlt={s.name}
                size={60}
                borderSize={2}
                shape="squircle"
                borderColors={previewColors}
                showSheetDefault
                className={styles.avatar}
              />
              <span className={styles.premadeName}>{s.name}</span>
              <Button
                className={styles.playButton}
                isLoading={false}
                onClick={() => onLoadPreviewSheet(s.id)}
                primaryBackground="rgb(var(--color-theme-accent))"
              >
                View
              </Button>
            </div>
          ))}
        </div>
      </>
    );
  }, [dispatch, freeProduct, game, gameMaterialSheets, previewRoom, previewColors]);

  return (
    <div className={`${styles.container} ${className || ''} scrollbars-dark`}>
      {isLoading && <LoadingSpinner size={42} />}
      {!isLoading &&
        game &&
        (freeProduct ? (
          <div className={clsx(styles.content, 'scrollbars-dark')}>
            {renderPreGenSheets()}
            {roomHasPreGenSheetsAndTemplates && (
              <div className={styles.divider}>
                <hr className={styles.hr} />
                <span>or</span>
                <hr className={styles.hr} />
              </div>
            )}
            {renderPreGenSheetTemplates()}
          </div>
        ) : (
          <>
            <h3 className={styles.heading}>This game doesn't come with any pre-made characters or sheets</h3>
            <p className={styles.info}>
              Our creator tools are free to use for you to make your own
              <br />
              <br />
              <a
                href="https://playrole.notion.site/Sheet-Builder-4a12e58d999d47439933e5e8e5e4e70b"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here
              </a>
            </p>
          </>
        ))}
    </div>
  );
}

export default PreviewSheetCreator;
