import styles from './Card.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CardView } from 'models/Card';
import PropTypes from 'prop-types';
import RoomAction from 'store/actions/RoomAction';
import posthog from 'posthog-js';
import { randomCard } from 'utilities';

class CardPile extends Component {
  onClick = () => {
    const { room, updateCard, currentView, cards, userId: id, isDiscard, isGm, isOpenDraw } = this.props;
    if ((currentView === CardView.TABLE || isDiscard) && !isGm && !isOpenDraw) return;

    const card = isDiscard && cards.length > 0 ? cards[cards.length - 1] : randomCard(cards);
    if (!card) return;

    let userId = null;
    switch (currentView) {
      case CardView.PLAYER:
        userId = id;
        break;
      case CardView.TABLE:
      default:
        break;
    }

    if (isDiscard) {
      card.isDrawn = true;
      card.isDiscarded = false;
      card.userId = userId;
      card.updatedAt = Date.now();
      updateCard(room.guid, card);
    } else {
      card.isDrawn = true;
      card.userId = userId;
      card.updatedAt = Date.now();
      updateCard(room.guid, card);
    }

    posthog.capture('table - card drawn', {
      'card': card,
    });
  };

  renderPile = () => {
    const { isDiscard } = this.props;
    return (
      <>
        <div onClick={this.onClick} className={styles.cardBack}>
          <div className={styles.cardBackIcon} />
        </div>
        {isDiscard && <div className={styles.cardOverlay} />}
      </>
    );
  };

  renderTitle = () => {
    return this.props.isDiscard ? 'Discard' : 'Deck';
  };

  render() {
    const { cards } = this.props;
    // TEMP:
    const isTarot = this.props.currentDeck === 'swordsfallTarot';
    const classNames = ['box-shadow', styles.container, styles.isOutline, styles.isPile];
    if (isTarot) classNames.push(styles.isTarot);

    return (
      <div className={styles.pileContainer}>
        <div className={classNames.join(' ')}>{cards.length > 0 && this.renderPile()}</div>
        <div className="heading2">{this.renderTitle()}</div>
      </div>
    );
  }
}

CardPile.propTypes = {
  isDiscard: PropTypes.bool.isRequired,
  isGm: PropTypes.bool.isRequired,
  isOpenDraw: PropTypes.bool.isRequired,
};

CardPile.defaultProps = {
  isDiscard: false,
  isGm: false,
  isOpenDraw: false,
};

const mapDispatchToProps = {
  updateCard: RoomAction.updateCard,
};

export default connect(null, mapDispatchToProps)(CardPile);
