import { useState } from 'react';
import Toast from './Toast';
import posthog from 'posthog-js';
import { Link } from 'react-router-dom';
import styles from './Toast.module.css';

type Props = {};

type BannerData = {
  title: string;
  body: string;
  link: string;
  version: number;
};

export default function ChangelogToast({}: Props) {
  const [bannerData, setBannerData] = useState<BannerData | null>(null);

  setTimeout(() => {
    posthog.onFeatureFlags(() => {
      const matchedFlagPayload = posthog.getFeatureFlagPayload('changelog-banner') as BannerData;
      if (!bannerData && !!matchedFlagPayload) setBannerData(matchedFlagPayload);
    });
  }, 3000); // FF are not ready on initial load; use timeout hack for now...

  const onClose = () => {
    posthog.capture('changelog toast - dismissed', {
      $set: { changelog_banner_version: bannerData?.version },
    });
    setBannerData(null);
    posthog.reloadFeatureFlags();
  };

  return bannerData ? (
    <Toast
      body={
        <>
          {bannerData?.body}{' '}
          <Link
            to={bannerData?.link}
            className={styles.link}
            target="_blank"
            onClick={() => posthog.capture('changelog toast - clicked link')}
          >
            Read here
          </Link>
        </>
      }
      title={bannerData.title}
      onClose={onClose}
    />
  ) : null;
}
